import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SideBar, Header} from '../pages';
import { csvTojson } from '../_helpers';
import { getFirestore } from 'redux-firestore';
import datacsv from '../_helpers/numbers-countries.csv';
import { readRemoteFile } from 'react-papaparse'
import FlagIcon from '../_helpers/FlagIcon';
import {savePriceAdminFirebase, deletePriceAdminFirebase, savePriceEmailAdminFirebase, savePriceUserAdminFirebase } from '../_actions';

class AdminPrices extends Component {
    constructor(props){
        super(props);

        this.state = {
            table1: [],
            email: {
                price: 0,
                className: 'primary',
                valueButton: 'Guardar',
                isLoading: false
            },
            user: {
                price: 0,
                className: 'primary',
                valueButton: 'Guardar',
                isLoading: false
            }
        }

    }

    componentDidMount(){
        const { user, admin } = this.props;

        if(!user.uid || !admin) return false;
        
        this.getAllPricesFirestore();
    }
 
    getAllPricesFirestore = () => {
        const firestore = getFirestore();
        console.log("1")

        firestore.collection('admin').doc('prices')
        .collection('countries').onSnapshot((querySnapshot) => {
            let cc = [];
            querySnapshot.forEach((doc) => {
                const codef = doc.id;
                const { indicador, price } = doc.data();
                cc.push({
                    code: codef,
                    number: indicador,
                    price: price
                });
            });
            console.log("2")
            this.getAllPrices(cc);
        });

        firestore.collection('admin').doc('prices').collection('emails').doc('values')
            .get().then(doc => {
                if(doc.exists){
                    const { price } = doc.data();
                    const { email } = this.state;
                    email.price = price;
                    this.setState({
                        email
                    });
                }
            });

        firestore.collection('admin').doc('prices').collection('user').doc('values')
            .get().then(doc => {
                if(doc.exists){
                    const { price } = doc.data();
                    const { user } = this.state;
                    user.price = price;
                    this.setState({
                        user
                    });
                }
            });
    }

    getAllPrices = (cc) => {
        readRemoteFile(datacsv, {
            complete: (results) => {
                let arr = [];

                results.data.forEach((d) => {
                    let _price = 0;
                    cc.forEach((c) => {
                        if(d[3].toLowerCase() == c.code.toLowerCase()){
                            console.log(c.price);
                            _price = c.price;
                        }
                    });
                    arr.push({
                            country: d[1],
                            code: d[3].toLowerCase(),
                            number: d[5], 
                            price: _price,
                            className: 'primary',
                            valueButton: 'Guardar',
                            isLoading: false
                    });
                    
                });
                console.log(arr.length);
                this.setState({
                    // table1: this.getAllPrices(cc),
                    table1: arr,
                    buttonState: ''
                });
               
                console.log(this.state.table1.length)
            }
        });

    }

    changePriceEmail = e => {
        const { value } = e.target;
        const { email } = this.state;

        email.price = value;
        this.setState({
            email
        });
    }

    changePriceUser = e => {
        const { value } = e.target;
        const { user } = this.state;

        user.price = value;
        this.setState({
            user
        });
    }

    updatePrice(e, i) {
        const { table1 } = this.state;

        table1[i].price = e.target.value;

        this.setState({
            table1
        });
    }

    savePriceEmail =  e => {
        e.preventDefault();
        const { email } = this.state;
        email.isLoading = true;
        email.valueButton = 'Loading...';
        this.setState({
            email
        });

        setTimeout(() => {
            const isN = isFinite(String(email.price));
            email.className = (isN && email.price >= 0) ? 'success' : 'danger';
            email.valueButton = (isN && email.price >= 0) ? 'Guardado' : 'Erro';

            if(isN){
                if(email.price >= 0){
                    this.props.savePriceEmailAdminFirebase(email.price);
                }
            }

            this.setState({
                email
            });
        }, 2000);


        setTimeout(() => {
            email.className = 'primary';
            email.valueButton = 'Guardar';
            email.isLoading = false;

            this.setState({
                email
            });
        }, 3000);
    }

    savePriceUser =  e => {
        e.preventDefault();
        const { user } = this.state;
        user.isLoading = true;
        user.valueButton = 'Loading...';
        this.setState({
            user
        });

        setTimeout(() => {
            const isN = isFinite(String(user.price));
            user.className = (isN && user.price >= 0) ? 'success' : 'danger';
            user.valueButton = (isN && user.price >= 0) ? 'Guardado' : 'Erro';

            if(isN){
                if(user.price >= 0){
                    this.props.savePriceUserAdminFirebase(user.price);
                }
            }

            this.setState({
                user
            });
        }, 2000);


        setTimeout(() => {
            user.className = 'primary';
            user.valueButton = 'Guardar';
            user.isLoading = false;

            this.setState({
                user
            });
        }, 3000);
    }

    handleClick(i){
        const { table1 } = this.state;
        let price = table1[i].price;
        table1[i].isLoading = true;
        table1[i].valueButton = 'Loading...';
        this.setState({
            table1
        });


        setTimeout(() => {
            const isN = isFinite(String(price));
            table1[i].className = (isN && price >= 0) ? 'success' : 'danger';
            table1[i].valueButton = (isN && price >= 0) ? 'Guardado' : 'Erro';

            if(isN) {
                if(price > 0){
                    this.props.savePriceAdminFirebase(table1[i].code, price, table1[i].number);
                }else if(price == 0){
                    this.props.deletePriceAdminFirebase(table1[i].code);
                }
            }

            this.setState({
                table1
            })
           
                
        }, 2000);

        setTimeout(() => {
            table1[i].className = 'primary'
            table1[i].valueButton = 'Guardar';
            table1[i].isLoading = false
            
            this.setState({
                table1
            })
        }, 3000);
    }

    render() {
        const { user, admin } = this.props;

        const {buttonState} = this.state;

        if(!user.uid) return <Redirect to="/login" />
        if(admin == false) return <Redirect to='/' />

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminPrices</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Prices</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body">

                                                <div className="form-group row">
                                                    <label for="email" className="col-sm-2 col-form-label">
                                                        <i className="far nav-icon fas fa-envelope" style={{color:"blue","font-size":"20px"}}></i>
                                                        <span>&nbsp;Email</span>
                                                    </label>
                                                    <div className="col-sm-2">
                                                        <input
                                                            type="text"
                                                            onChange={this.changePriceEmail}
                                                            className="form-control"
                                                            value={this.state.email.price}
                                                            name="email"
                                                        />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <button
                                                            onClick={this.savePriceEmail}
                                                            class={"btn btn-"+this.state.email.className}
                                                            disabled={this.state.email.isLoading}>
                                                                {this.state.email.valueButton}
                                                            </button>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="user" className="col-sm-2 col-form-label">
                                                        <i className="far nav-icon fas fa-user" style={{color:"red","font-size":"20px"}}></i>
                                                        <span>&nbsp;User</span>
                                                    </label>
                                                    <div className="col-sm-2">
                                                        <input
                                                            type="text"
                                                            onChange={this.changePriceUser}
                                                            className="form-control"
                                                            value={this.state.user.price}
                                                            name="user"
                                                        />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <button
                                                            onClick={this.savePriceUser}
                                                            class={"btn btn-"+this.state.user.className}
                                                            disabled={this.state.user.isLoading}>
                                                                {this.state.user.valueButton}
                                                            </button>
                                                    </div>
                                                </div>

                                                {this.state.table1.map((tab, i) =>
                                                <div className="form-group row">
                                                    <label for={tab.code} className="col-sm-2 col-form-label"><FlagIcon code={tab.code} /> {tab.country} (+{tab.number})</label>
                                                    <div className="col-sm-2">
                                                        <input 
                                                            type="text" 
                                                            onChange={(e) => this.updatePrice(e, i)} 
                                                            className="form-control" 
                                                            value={this.state.table1[i].price} 
                                                            name={tab.code} 
                                                        />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <button 
                                                            onClick={!this.state.table1[i].isLoading ? ()=> this.handleClick(i) : null} 
                                                            className={"btn btn-"+this.state.table1[i].className}
                                                            disabled={this.state.table1[i].isLoading}>
                                                                {this.state.table1[i].valueButton}
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        csvTojson : (csv, split) => csvTojson(csv, split),
        savePriceAdminFirebase : (code, price, indicador) => dispatch(savePriceAdminFirebase(code, price, indicador)),
        deletePriceAdminFirebase : (code) => dispatch(deletePriceAdminFirebase(code)),
        savePriceEmailAdminFirebase : (price) => dispatch(savePriceEmailAdminFirebase(price)),
        savePriceUserAdminFirebase : (price) => dispatch(savePriceUserAdminFirebase(price))
    }
}
const connected = connect(mapState, mapDispatchToProps)(AdminPrices);
export { connected as AdminPrices }