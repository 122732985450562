import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, SideBar } from './';
import { getFirestore } from 'redux-firestore';

import CardForm from "./utils/CardForm";
import TablePrice from './utils/TablePrice';

import Modal from 'react-bootstrap/Modal';
import "./utils/style.css";

import { StripeProvider, Elements } from 'react-stripe-elements';
import {loadStripe} from '@stripe/stripe-js';

class Payment extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            credits: [],
            value: 0,
            price: 0,
            credito: 0,
            showModal: false,
            showCard: false,
            element: {},
            pursh: -1,
            elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
            addTablePrices: [
                {
                    color: "#8399AA",
                    img: "airplan1.png",
                    description: "Paper Airplane"
                },
                {
                    color: "#6EA96E",
                    img: "airplan2.png",
                    description: "Plane"
                },
                {
                    color: "#009ADA",
                    img: "airplan3.png",
                    description: "Jet"
                }
            ]
        };

        window.addEventListener('resize', () => {
            if(window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
                this.setState({ elementFontSize: '14px'});
            }else if(window.innerWidth >= 450 && this.state.elementFontSize !== '18px'){
                this.setState({elementFontSize: '18px'});
            }
        });
    }

    componentDidMount = () => {
        if(!this.props.user.uid) return false;
        this.getAllCredits();
    }

    getAllCredits = () => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('credits')
        .collection('values').onSnapshot((querySnapshot) => {
            let cc = [];
            querySnapshot.forEach((doc) => {
                const uid = doc.id;
                const { credit, price } = doc.data();
                cc.push({
                    uid: uid,
                    credit: credit,
                    price: price
                });
            });

            cc.sort((a,b) => parseInt(a.credit) > parseInt(b.credit) ? 1 : -1);
            const { addTablePrices } = this.state;
            cc.map((tab, i) => {
                addTablePrices[i].credits = tab.credit;
                addTablePrices[i].price = tab.price;
                addTablePrices[i].uid = tab.uid;
            });
            this.setState({
                credits: cc,
                value: cc.length > 0 ? cc[0].uid : null,
                price: cc.length > 0 ? cc[0].price : null,
                credito: cc.length > 0 ? cc[0].credit : null
            });
        })
    }

    handleChange = (e) => {
        const { value } = e.target;

        this.setState({
            value: value
        });


        this.state.credits.forEach((cre) => {
            if(cre.uid === value){
                this.setState({
                    price: cre.price,
                    credito: cre.credit
                });
            }
        })

    }

    onSuccessfulCheckout = () => {
        this.setState({
            credito: this.state.element.credits,
            showModal: true
        });

        this.handleClosePurchase();
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false
        })
    }

    onShowCard = (element) => {
        this.setState({
            showCard: true,
            element: element,
        });
    }

    onHideCard = () => {
        this.setState({
            showCard: false,
            element: {}
        });
    }

    handlePurchase = index => {
        const { pursh, addTablePrices } = this.state;
        if(parseInt(pursh) !== parseInt(index)){
            this.setState({
                pursh: index
            });

            this.onShowCard(addTablePrices[index]);
        }
    }

    handleClosePurchase = () => {
        this.setState({
            pursh: -1
        });

        this.onHideCard();
    }

    render() {
        const { user } = this.props;
        const { addTablePrices, elementFontSize, element } = this.state;

        console.log(this.props.profile.name);

        if(!user.uid) return <Redirect to="/login" />

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Payments</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Payment</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                {this.state.credits.length > 0
                                    ?
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body">
                                                <TablePrice
                                                    prices={addTablePrices}
                                                    handlePurchase={this.handlePurchase}
                                                    handleClosePurchase={this.handleClosePurchase}
                                                    pursh={this.state.pursh}
                                                />
                                                
                                                {this.state.showCard ? 
                                                    <div className="col-md-12">
                                                        <StripeProvider apiKey="pk_test_51H8lBRAoKYak6EjVexnGOiVajnU4xQE360c8ICbk8KE40FDl5KI1cMfbAr4Ug2c7RtAlpuHWEgI1URaoIi1vu66X00jQzYxwBh">
                                                            <Elements>
                                                                <CardForm 
                                                                    fontSize={elementFontSize} 
                                                                    onSuccessfulCheckout={this.onSuccessfulCheckout}
                                                                    data={element}
                                                                    profile={this.props.profile}
                                                                    user={this.props.user}
                                                                />
                                                            </Elements>
                                                        </StripeProvider>

                                                    </div>
                                                    : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirmação de pagamento</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Recebeu novos {this.state.credito} créditos</Modal.Body>
                                        <Modal.Footer>
                                             <button variant="secondary" onClick={this.handleCloseModal}>
                                                Close
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                :
                                <div className="text-center">
                                    <img width="572" src="https://www.seekpng.com/png/full/990-9902766_keep-calm-its-not-over-yet-poster-not.png" className="img-circle elevation-2" />
                                </div> 
                            }
                            </div>
                        </section>

                    </div>

                </div>
            </div>
            
        )

    }

}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {

}

const connected = connect(mapState,  {})(Payment);
export { connected as Payment }


