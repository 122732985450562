import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, SideBar } from './';



class Support extends Component {
    constructor(props){
        super(props);

        
    }

  

 

    render() {
       
        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Suporte</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Home</a></li>
                                            <li className="breadcrumb-item active">Suporte</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div className="card card-primary card-outline">
                                            <div className="card-header">
                                                <h3 className="card-title">New Support</h3>
                                            </div>
                                        <div className="card-body">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Support);
export { connected as Support};