import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SideBar, Header} from '../pages';

import { getFirestore } from 'redux-firestore';

class AdminFeedbacks extends Component {


    constructor(props){
        super(props);

        this.state = {
            table: []
        }
    }

    componentDidMount(){
        const { user, admin } = this.props;

        if(!user.uid || !admin) return false;
      
        this.getFeedbacks();
    }

    getFeedbacks = () => {
        const firestore = getFirestore();

        firestore.collection('users').onSnapshot((docs) => {
            let arr = [];
            docs.forEach((doc) => {
                const { name } = doc.data();
                firestore.collection('users').doc(doc.id).collection('feedback').get().then((data) => {
                    data.forEach(d => {
                        const { category, feedback, opinion } = d.data();
                        arr.push({
                            id: d.id,
                            name: name,
                            category: category,
                            opinion: opinion,
                            feedback: feedback
                        });
                    });

                    this.setState({
                        table: arr
                    });
                });
                
            });
        });
        
    }
    
    render() {
        const { user, admin } = this.props;

        if(!user.uid) return <Redirect to="/login" />
        if(admin == false) return <Redirect to='/' />
            
        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminFeedbacks</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Feedbacks</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-header">
                                                <h3 className="card-title">Feedbacks</h3>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="mailbox-controls">
                                                    <div></div>
                                                </div>

                                                <div className="table-responsive mailbox-messages">
                                                    <table className="table table-hover table-striped">
                                                    <thead>
                                                        <td>ID</td>
                                                        <td>Name</td>
                                                        <td>Opinion</td>
                                                        <td>Category</td>
                                                        <td>Message</td>
                                                    </thead>
                                                        {(() => {
                                                            if(this.state.table.length > 0){
                                                                return (
                                                                    
                                                                    <tbody>
                                                                        {this.state.table.map(tab =>
                                                                            <tr>
                                                                                <td className="mailbox-subject">{tab.id}</td>
                                                                                <td className="mailbox-subject">{tab.name}</td>
                                                                                <td className="mailbox-subject">{tab.opinion}</td>
                                                                                <td className="mailbox-subject">{tab.category}</td>
                                                                                <td className="mailbox-subject">{tab.feedback}</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                );
                                                            }else{
                                                                return (
                                                                    <td className="text-center">No Feedbacks</td>
                                                                );
                                                            }
                                                        })()}
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const connected = connect(mapState, {})(AdminFeedbacks);
export { connected as AdminFeedbacks }