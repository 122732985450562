// export * from './App';
export * from './Register';
export * from './Login';
export * from './Forgot';
export * from './Home';
export * from './SideBar';
export * from './Header';
export * from './Message';
export * from './Profile';
export * from './Payment';
export * from './Emails';
export * from './Support';
export * from './Heatmap';
export * from './Feedback';
