import { getFirestore } from "redux-firestore";
import { getFirebase  } from "react-redux-firebase";

// export const sendMessageFirebase = (destino, origem, message, uid) => {

const getRules = (admin, uid = null) => {
    const firestore = getFirestore();
    let fire = admin ?
        firestore.collection('admin').doc('rules').collection('values')
        :
        firestore.collection('users').doc(uid).collection('rules');

    return fire.get().then((querySnapshot) => {
        let origens = [];
        querySnapshot.forEach((doc) => {
            const { order, smsdisponiveis, numberphone, areaCode, zonasms } = doc.data();

            origens.push({
                id: doc.id,
                order,
                smsdisponiveis,
                numberphone,
                areaCode,
                zonasms
            });
        });

        origens.sort((a,b) => parseInt(a.order) - parseInt(b.order));

        return origens;
    });
}

const sendMessageDestino = (destino, doc, message, falcon, uid, dateTime) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const database = firebase.database();

    const ref = falcon ?
        firestore.collection('admin').doc('rules').collection('values') :
        firestore.collection('users').doc(uid).collection('rules');

    if(destino != null && destino.length > 0){
        let destino_not_send = [];
        for(let i = 0; i < destino.length; i++){
            if(doc.smsdisponiveis == 0){
                return;
            }

            if(destino[i].includes("+"+doc.zonasms.id)){


                let data = {
                    message,
                    origem: "+"+doc.areaCode+""+doc.numberphone,
                    destino: destino[i],
                    falcon,
                    send: new Date().toLocaleString(),
                    check: 0,
                    dateTime
                };

                firestore.collection('users').doc(uid).collection('messages')
                .add(data).then((res) => {
                    database.ref("test/"+data.origem).child(res.id).set({
                        'destino':data.destino,
                        'message':data.message,
                        'dateTime': data.dateTime,
                        'hasAlarm': false
                    });

                });

                // doc.smsdisponiveis -= 1;

                // ref.doc(doc.id).update({
                //     smsdisponiveis: doc.smsdisponiveis
                // });
            }else{
                destino_not_send.push(destino[i]);
            }
        }

        destino = destino_not_send;

        return destino;
    }

    return [];
}


var x = "Caro Cliente, a pensar em si baixamos o preco dos sacos de papel asa retorcida, 4 medidas e 13 cores disponiveis, min. 50 unidades desde 0,12€ (+IVA). dsaddsadds";

var z = parseInt(x.length /160) +1 ;


export const sendMessageFirebase = (destino, message, uid, dateTime) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {


        var BreakException = {};

        try {
            getRules(false, uid).then((res) => {
                res.forEach((doc) => {
                    console.log(doc.numberphone);
                    if(doc.numberphone == "falcon"){
                        console.log("HERE");
                        getRules(true).then((res1) => {
                            res1.forEach((doc) => {
                                destino = sendMessageDestino(destino, doc, message, true, uid, dateTime);
                            });
                        });

                        throw BreakException;
                    }

                    destino = sendMessageDestino(destino, doc, message, false, uid, dateTime);
                });
            });
        } catch (e) {
            if(e !== BreakException) throw e;
        }
    }
}

export const calculateCredits = (destinos, creditos, uid, response) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        let prices = [];
        firestore.collection('admin').doc('prices')
        .collection('countries').get()
        .then((res) => {
            res.forEach((e) => {
                let dat = e.data();
                prices.push({
                    indicator: dat.indicador,
                    price: dat.price
                });
            });

            let pric = 0;

            destinos.forEach((d) => {
                prices.forEach((p) => {
                    if(p.indicator === d){
                        pric += parseFloat(p.price);
                    }
                });
            });

            if(parseFloat(pric) > parseFloat(creditos)){
                response(pric, creditos, false);
                return;
            }


            firestore.collection('users').doc(uid).set({
                'creditos': parseFloat(creditos) - parseFloat(pric)
            });

            response(pric, creditos, true);
        });
    }
}
