import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SideBar, Header} from '../pages';
import { blockUser, saveProfileCreditos } from '../_actions';
import { getFirestore } from 'redux-firestore';

class AdminUsers extends Component {


    constructor(props){
        super(props);

        this.state = {
            table: [], 
            showEditCredts: '',
            creditos: 0
        }
    }

    componentDidMount(){
        const { user, admin } = this.props;

        if(!user.uid || !admin) return false;
      
        this.getUsers();
    }

    getUsers = () => {
        const firestore = getFirestore();

        firestore.collection('users').onSnapshot((docs) => {
            let data = [];
            docs.forEach((doc) => {
                const { admin, name, block, creditos } = doc.data();
                data.push({
                    id: doc.id,
                    admin: admin,
                    name: name,
                    block: block, 
                    creditos: creditos
                });
            });

            this.setState({
                table: data,
                showEditCredts: '',
                creditos: 0
            });

        });
        
    }

    blockUser = (id, block) => {
        this.props.blockUser(id, block);
    }

    showEditCredts = (i) => {
        const { table } = this.state;
        console.log(table[i])
        this.setState({
            showEditCredts: table[i].id,
            creditos: table[i].creditos
        });
    }

    handleChangeCredits = e => {
        this.setState({
            creditos: e.target.value
        });
    }

    handleSaveCredits = e => {
        e.preventDefault();

        const { showEditCredts, creditos } = this.state;

        this.props.saveProfileCreditos(showEditCredts, creditos);
        this.getUsers();
    }
    
    render() {
        const { user, admin } = this.props;

        if(!user.uid) return <Redirect to="/login" />
        if(admin == false) return <Redirect to='/' />

        const { showEditCredts, creditos } = this.state;
            
        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminUsers</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-header">
                                                <h3 className="card-title">Users</h3>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="mailbox-controls">
                                                    <div></div>
                                                </div>

                                                <div className="table-responsive mailbox-messages">
                                                    <table className="table table-hover table-striped">
                                                    <thead>
                                                        <td>ID</td>
                                                        <td>Name</td>
                                                        <td>Credits</td>
                                                        <td>Admin</td>
                                                        <td>Actions</td>
                                                    </thead>
                                                        {(() => {
                                                            if(this.state.table.length > 0){
                                                                return (
                                                                    
                                                                    <tbody>
                                                                        {this.state.table.map((tab, i) =>
                                                                            <tr>
                                                                                <td className="mailbox-subject">{tab.id}</td>
                                                                                <td className="mailbox-subject">{tab.name}</td>
                                                                                <td className="mailbox-subject">
                                                                                    {showEditCredts == tab.id ? 
                                                                                        <div style={{"display":"-webkit-box"}}>
                                                                                            <input type="text" value={creditos} onChange={this.handleChangeCredits} size="4" />
                                                                                            <button onClick={this.handleSaveCredits}>Save</button>
                                                                                        </div> : 
                                                                                        <>
                                                                                            {tab.creditos} 
                                                                                            <span style={{cursor:"pointer"}} onClick={() => this.showEditCredts(i)}>
                                                                                                <i className="fa fa-pencil"></i>
                                                                                            </span>
                                                                                        </>
                                                                                    } 
                                                                                </td>
                                                                                <td className="mailbox-subject">{tab.admin ? 'Sim' : 'Nao'}</td>
                                                                                <td className="mailbox-subject"><button onClick={() => this.blockUser(tab.id, !tab.block)}>{tab.block ? "Unblock" : "Block"}</button></td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                );
                                                            }else{
                                                                return (
                                                                    <td className="text-center">No Users</td>
                                                                );
                                                            }
                                                        })()}
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        blockUser : (uid, block) => dispatch(blockUser(uid, block)),
        saveProfileCreditos : (uid, credits) => dispatch(saveProfileCreditos(uid, credits))
    }
}

const connected = connect(mapState, mapDispatchToProps)(AdminUsers);
export { connected as AdminUsers }