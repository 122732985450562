import { getFirestore } from 'redux-firestore';

export const saveWebHeatmap = (uid, name, website) => {
  return (dispatch, getState) => {
    const firestore = getFirestore();
    console.log("name: "+name+"; website: "+website);

    return firestore.collection('heatmaps').doc(uid).collection('heatmap').get()
      .then(res => {
        let exists = false;
        res.forEach(e => {
          let dat = e.data();
          if(dat.website === website){
            exists = true;
            console.error("website ja existe");
          }
        });

        if(!exists){
          return firestore.collection('heatmaps').doc(uid).collection('heatmap').add({
            name: name,
            website: website
          }).then(() => {
            return { success: true };
          });
        }else{
          return { success: false, message: "website ja existe" };
        }
    }).catch( e => {
      return { success: false, message: e.message };
    });
  }
}

export const removeHeatmap = (uid, web) => {
  return (dispatch, getState) => {
    const firestore = getFirestore();

    firestore.collection('heatmaps').doc(uid).collection('heatmap').onSnapshot((docs) => {
      docs.forEach(doc => {
          const { website } = doc.data();
          if(website == web){
            doc.ref.delete();
          }
      });
    });
  }
};
