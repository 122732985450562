const initState = {
    authError: null
};

export function authentication(state = initState, action){
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                ...state,
                authError: null
            };

        case 'LOGIN_ERROR':
            console.log('login error');
            return {
                ...state,
                authError: 'Login failed'
            };

        case 'LOGOUT_SUCCESS':
            console.log('logout success');
            return state;

        case 'REGISTER_SUCCESS':
            console.log('register success');
            return  {
                ...state,
                authError: null
            };

        case 'REGISTER_ERROR':
            console.log('register error');
            return {
                ...state,
                authError: action.err.message
            }

        case 'UPDATE_SUCCESS':
            console.log('update success');
            return {
                ...state,
                authError: null
            }

        case 'FOGOT_SUCCESS':
            return {
                ...state,
                authError: 'Click on the URL provided in the email and enter a new password'
            }

        default:
            return state;
    }
}