import { getFirestore } from "redux-firestore";

export const login = (credentials) => {
    console.log(credentials);

    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(() => {
                dispatch({ type: 'LOGIN_SUCESS'});
            }).catch((err) => {
                dispatch({type: 'LOGIN_ERROR',  err})
            });
    }
}

const registerUserFirestore = (user) => {
    const firestore = getFirestore();

    firestore.collection('users').doc(user.uid).set({
        name: user.name,
        admin: false,
        creditos: 0,
        block: false
    }).then(() => {
        firestore.collection('users').doc(user.uid)
            .collection('rules').add({
                order: 1,
                areaCode: '',
                numberphone: 'falcon',
                smsdisponiveis: -1,
                zonasms: {
                    name: 'Todos',
                    locale: '',
                    flag: 'https://metasystems-international.com/site/templates/images/flags/svg/int.svg'
                }
            });
    }).catch(err => {
        alert('erro: '+err);
    });
}

export const loginFacebook = () => {
    return (dispatch, getStatem, { getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        let provider = new firebase.auth.FacebookAuthProvider();
        
        provider.setCustomParameters({
            'display': 'popup'
        });

        firebase.auth().signInWithPopup(provider)
            .then((res) => {

                firestore.collection('users').doc(res.user.uid)
                    .get().then(doc => {
                        if(!doc.exists){
                            registerUserFirestore({
                                'uid': res.user.uid,
                                'name': res.user.displayName
                            });
        
                            res.user.sendEmailVerification();
                        }
                    }).catch(err => {
                        console.log("error: "+err);
                    })

               
                dispatch({ type: 'LOGIN_SUCESS'});
                
            }).catch(err => {
                console.log(err);
                dispatch({type: 'LOGIN_ERROR',  err});
            });
    }
}

export const forgot = (email) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch({type: 'FOGOT_SUCCESS'});
            }).catch(err => {
                
            })
    }
}

export const logout = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut()
            .then(() => {
                dispatch({type: 'LOGOUT_SUCCESS'});
            });
    }
}

export const register = (newUser) => {
    console.log(newUser);
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password)
            .then(res => {

                registerUserFirestore({
                    'uid': res.user.uid,
                    'name': newUser.name
                })

                res.user.sendEmailVerification();
            }).then(() => {

                dispatch({ type: 'REGISTER_SUCCESS'});
            }).catch(err => {
                dispatch({ type: 'REGISTER_ERROR', err})
            });

    }
}


export const saveProfileName = (uid, name) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('users').doc(uid).update({
            name: name
        }).then(() => {
            dispatch({type: 'UPDATE_SUCCESS'});
        });
    }
}

export const blockUser = (uid, block) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('users').doc(uid).update({
            block: block
        }).then(() => {
            dispatch({type: 'UPDATE_SUCCESS'});
        })
    }
}

export const saveProfileCreditos = (uid, credit) => {
    console.log("uid: "+uid+" ; creditos: "+credit);
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        console.log("firestore");
        firestore.collection('users').doc(uid).update({
            creditos: credit
        }).then(() => {
            console.log('success');
            dispatch({type: 'UPDATE_SUCCESS'});
        }).catch(err => {
            console.log(err);
        });
    }
}

export const deleteAllAndSaveRulesUserFirbase = (uid, rules) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('users').doc(uid).collection('rules')
        .get().then(res => {
            res.forEach(e => {
                e.ref.delete();
            });

            rules.forEach(rule => {
                firestore.collection('users').doc(uid).collection('rules')
                .add({
                    order: rule.id,
                    areaCode: rule.areaCode,
                    numberphone: rule.numberphone,
                    smsdisponiveis: rule.smsdisponiveis,
                    zonasms: rule.zonasms
                });
            });
        })
    }
}

export const deleteAllRulesUserFirbase = (uid) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('users').doc(uid).collection('rules')
        .get().then((res) => {
            res.forEach((e) => {
                e.ref.delete();
            });
        });
    }
}

export const saveRuleUserFirebase = (uid, data) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('users').doc(uid).collection('rules')
        .add({
            order: data.id,
            areaCode: data.areaCode,
            numberphone: data.numberphone,
            smsdisponiveis: data.smsdisponiveis,
            zonasms: data.zonasms
        });
    }
}