import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { register } from '../_actions';

// import { userActions } from '../_actions';

class Register extends Component {

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            password: '',
            showModal: false,
            checkterms: false
        };
    }
    

    handleSubmit = (event) => {
        event.preventDefault();
        
        if(this.state.name && this.state.email && this.state.password && this.state.checkterms){
            this.props.register(this.state);
        }
    }

    handleChange = (event) =>{
        event.preventDefault();

        console.log(event.target);

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleCloseModal = e => {
        this.setState({
            showModal: false
        });
    }

    showTermos = e => {
        e.preventDefault();
        this.setState({
            showModal: true
        });
    }

    changeCheckTermos = e => {
        const { checked } = e.target;

        this.setState({
            checkterms: checked
        })

    }

    render() {
        const { user, authError } = this.props;
        if(user.uid) return <Redirect to="/" />

        return ( 
            <div className="container falc-register" style={{"font-size":"75%"}}>
                {alert.message &&
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
                <div className="card m-5 p-5 cardfalc">
                    <div className="login-logo">
                        <img src="img/dist/logo_mini.png" className="brand-image img-circle elevation-3" style={{opacity: .8}} />
                    </div>
                        <div className="card-body register-card-body">
                            <p className="login-box-msg">Welcome, Please sign up your account</p>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="form-inline form-group input-material" style={{"margin-bottom":"-15px"}}>
                                    <div className="col-sm-6 " style={{"padding-left":"0px"}}>
                                        <input 
                                            type="text" 
                                            name="name"
                                            value={user.name} 
                                            className="form-control" 
                                            id="firstname-field" 
                                            onChange={this.handleChange} 
                                            style={{"margin-top":"0px"}}
                                        />
                                        <label form="firstname-field">First Name</label>
                                    </div>
                                    <div className="col-sm-6" style={{"padding-left":"0px"}}>
                                        <input 
                                            type="text" 
                                            name="name"
                                            value={user.name} 
                                            className="form-control" 
                                            id="lastname-field" 
                                            onChange={this.handleChange}
                                            style={{"margin-top":"0px"}} 
                                        />
                                        <label form="lastname-field">Last Name</label>                                        
                                    </div>
                                </div>

                                <div className="form-group input-material">
                                    <input 
                                        type="text" 
                                        name="name"
                                        value={user.name} 
                                        className="form-control" 
                                        id="username-field" 
                                        onChange={this.handleChange} 
                                    />
                                    <label form="username-field">Username</label>
                                </div>

                                <div className="form-group input-material">
                                    <input 
                                        type="email" 
                                        name="email"
                                        value={user.email} 
                                        className="form-control" 
                                        id="email-field" 
                                        onChange={this.handleChange} 
                                    />
                                    <label form="email-field">Email</label>
                                </div>

                                <div className="form-group input-material">
                                    <input 
                                        type="password" 
                                        name="password"
                                        value={user.password} 
                                        className="form-control" 
                                        id="password-field" 
                                        onChange={this.handleChange} 
                                    />
                                    <label form="password-field">Password</label>
                                </div>

                                <div className="form-group input-material">
                                    <input 
                                        type="password" 
                                        name="password"
                                        value={user.password} 
                                        className="form-control" 
                                        id="confirmpassword-field" 
                                        onChange={this.handleChange} 
                                    />
                                    <label form="confirmpassword-field">Confirm Password</label>
                                </div>

                                
                                

                                <div class="custom-control custom-checkbox">
                                    <input 
                                        type="checkbox" 
                                        className="custom-control-input"
                                        id="customCheck1"
                                        name="check"
                                        checked={this.state.checkterms}
                                        onChange={this.changeCheckTermos}
                                    />
                                    <label class="custom-control-label" for="customCheck1">I agree with terms and conditions</label>
                                </div>

                                <div >
                                <div className="row" style={{"margin-top":"55px"}}>
                                    <div className="col text-center">
                                        <button type="submit" className="btn btn-primary" style={{width:"150px"}}>Sign up</button>
                                    </div>
                                </div>
                                <div className="row" style={{"margin-top":"10px"}}>
                                    <div className="col text-center m-1">
                                        <button style={{"border-radius":"15px 0 15px 0"}} className="btn btn-block btn-primary" onClick={this.handleLoginFacebook}>Sign up with <i className="fab fa-facebook-f mr-2"></i></button>
                                    </div>
                                    <div className=" col text-center m-1">
                                        <button style={{"border-radius":"15px 0 15px 0"}} className="btn btn-block btn-danger" onClick={this.handleLoginFacebook}>Sign up with <i className="fab fa-google mr-2"></i></button>
                                    </div>
                                </div>
                                </div>
                            </form>
                            <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Termos</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Texto termos</Modal.Body>
                                <Modal.Footer>
                                        <button variant="secondary" onClick={this.handleCloseModal}>Close</button>
                                </Modal.Footer>
                            </Modal>
                            <div className="row" style={{"margin-top":"15px"}}>
                                <span className="text-center col">Already have an account? <Link to="/login" className="text-center">Sign in</Link></span>
                            </div>

                            <div className="row" style={{"margin-top":"45px"}}>
                                <a href="!#" className="text-center col" onClick={this.showTermos}>Term of use. Privacy policy</a>
                            </div>
                        </div>
                        <div className="red-text center">
                            {authError ? <p>{authError}</p> : null }
                        </div>
                    </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        authError: state.authentication.authError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        register: (creds) => dispatch(register(creds))
    }
};


const connected = connect(mapStateToProps, mapDispatchToProps)(Register);

export { connected as Register };