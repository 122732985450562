import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SideBar, Header} from '../pages';

import { getFirestore } from 'redux-firestore';
import { getFirebase  } from "react-redux-firebase";
import { data } from 'jquery';

class AdminMessages extends Component {


    constructor(props){
        super(props);

        this.state = {
            table1: Array(),
            tablenotsent: Array()
        }
    }

    componentDidMount(){
        const { user, admin } = this.props;

        if(!user.uid || !admin) return false;
        
        this.getMessages();
        this.getMessagesNotSent();
    }

    getMessagesNotSent = () => {
        const firebase = getFirebase();
        const database = firebase.database();
        const firestore = getFirestore();

        firestore.collection('admin').doc('rules').collection('values').get().then((querySnapshot) => {
            let arr = [];
            querySnapshot.forEach((doc) => {
                const { areaCode, numberphone } = doc.data();
                let number = "+"+areaCode+""+numberphone;
                arr.push(number);
            });

            arr.forEach((a) => {
                console.log(a);
                database.ref("/test/"+a).on('value', (querySnapshot2) => {
                    let numbers = [];
                    querySnapshot2.forEach((doc) => {
                        const {destino, message} = doc.val();

                        let data = {
                            origem: a,
                            destino: destino,
                            message: message
                        };

                        numbers.push(data);
                    });

                    console.log(numbers);

                    this.setState({
                        tablenotsent: numbers
                    })
                });
            })
        })

    }

    getMessages = () => {
        const firestore = getFirestore();
        let { table1 } = this.state;
        const ref = firestore.collection('users').get().then((querySnapshot) => {
            let arr = [];
            querySnapshot.forEach((doc) => {
                const { name } = doc.data();
                firestore.collection('users').doc(doc.id).collection('messages').get().then((datas) => {
                    datas.forEach((sms) => {
                        const { destino, message, origem, send, falcon } = sms.data();
                        arr.push({
                            id: doc.id,
                            name: name,
                            origem: origem,
                            destino: destino,
                            falcon: falcon ? "Sim" : "Não",
                            message: message,
                            data: send
                        });
                    });
                    this.setState({
                        table1: arr
                    });
                })
            });
        });
    }


    render() {
        const { user, admin } = this.props;

        if(!user.uid) return <Redirect to="/login" />
        if(admin == false) return <Redirect to='/' />


        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminMessages</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Messages</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                {this.state.tablenotsent.length > 0 ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card card-danger card-outline">
                                                        <div className="card-header">
                                                            <h3 className="card-title">Messagens por enviar</h3>
                                                        </div>
                                                        <div className="card-body p-0">
                                                            <div className="mailbox-controls"><div></div></div>
                                                            <div className="table-responsive mailbox-messages">
                                                                <table className="table table-hover table-striped">
                                                                    <thead>
                                                                        <td>Destino</td>
                                                                        <td>Origem</td>
                                                                        <td>Message</td>
                                                                    </thead>

                                                                    <tbody>
                                                                        {this.state.tablenotsent.map(tab => 
                                                                            <tr>
                                                                                <td className="mailbox-subject">{tab.destino}</td>
                                                                                <td className="mailbox-subject">{tab.origem}</td>
                                                                                <td className="mailbox-subject">{tab.message}</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-header">
                                                <h3 className="card-title">Messages</h3>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="mailbox-controls">
                                                    <div></div>
                                                </div>

                                                <div className="table-responsive mailbox-messages">
                                                    <table className="table table-hover table-striped">
                                                    <thead>
                                                        <td>ID</td>
                                                        <td>Name</td>
                                                        <td>Destino</td>
                                                        <td>Origem</td>
                                                        <td>Falcon</td>
                                                        <td>Mensagem</td>
                                                        <td>Data</td>
                                                    </thead>
                                                        {(() => {
                                                            if(this.state.table1.length > 0){
                                                                return (
                                                                    
                                                                    <tbody>
                                                                        {this.state.table1.map(tab =>
                                                                            <tr>
                                                                                <td className="mailbox-subject">{tab.id}</td>
                                                                                <td className="mailbox-subject">{tab.name}</td>
                                                                                <td className="mailbox-subject">{tab.destino}</td>
                                                                                <td className="mailbox-subject">{tab.origem}</td>
                                                                                <td className="mailbox-subject">{tab.falcon}</td>
                                                                                <td className="mailbox-subject">{tab.message}</td>
                                                                            
                                                                                <td className="mailbox-subject">{tab.data}</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                );
                                                            }else{
                                                                return (
                                                                    <td className="text-center">No Users</td>
                                                                );
                                                            }
                                                        })()}
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const connected = connect(mapState, {})(AdminMessages);
export { connected as AdminMessages }