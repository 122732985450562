import React, { useMemo, useState  } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import useResponsiveFontSize from "./useResponsiveFontSize";
import axios from 'axios';
import { connect } from 'react-redux';

import World from "./World";

import { saveProfileCreditos } from '../../_actions';
import { Component } from "react";
import { withTheme } from "@material-ui/core";

const useOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: 'white',

        backgroundColor: 'transparent',
        outline: 'none',
        display: 'inline-block',
        boxShadow: 'none',
        border: 'none',
        borderBottom: '3px solid #ffffff',
        borderRadius: 0,
        textAlign: 'left',

        letterSpacing: '0.035em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },



        padding,

      },
      invalid: {
        color: 'white',
        backgroundColor: '#9e2146'
      },
    },
  };

};

const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleClick = () => {
  console.log('[click]');
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};

class _CardForm extends Component {

  constructor(props){
    super(props);
  }

  handleSubmit = async e => {
    e.preventDefault();

    const url = "https://falcon-g1ii.onrender.com/api/public/payment_intents";

    try {
      const { data: clientSecret } = await axios.post(url, {
        amount: this.props.data.price*100
      });

      const { stripe, elements } = this.props;

      const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement('cardNumber'),
            billing_details: {
              name: this.props.profile.name
            }
        });

        if(payload.error){
            console.log("error: "+payload.error.message);
            return;
        }

        const { error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: payload.paymentMethod.id
        });

        if(error){
            console.log("error 1: "+error.message);
            return;
        }else{
          let crd = parseInt(this.props.profile.creditos)+parseInt(this.props.data.credits);
          console.log(crd);
          this.props.saveProfileCreditos(this.props.user.uid, crd);

          this.props.onSuccessfulCheckout();

        }

        console.log("[PaymentMethod]", payload);
    }catch(e){
      console.log("eorro", e.message);
    }




    if(this.props.stripe){
      this.props.stripe.createToken()
      .then(payload => console.log(['token => ', payload]));
    }else{
      console.log("Stripe hasn't loaded yet.");
    }
  }



  render(){
    const options = useOptions();
    console.log(this.props);
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="cardfalcon">
        <span className="titlecardfalcon">FALCON</span>
        <div className="chipcardfalcon">
          <img src="img/dist/chipcard.png" className="chipcardfalcon-img" />
        </div>

        <div className="cardworld"><World color={this.props.data.color} /></div>
        <span className="numbercardfalcon">
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...useOptions(this.props.fontSize)}
          />
        </span>
        <span className="namecardfalcon">
          {/* <input type="text" className="StripeElement1" /> */}
          {this.props.profile.name}
        </span>
        <div className="textvalidthr">VALID THRU</div>
        <span className="datavalidcardfalcon">
          <CardExpiryElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...useOptions(this.props.fontSize)}
          />
        </span>

        <span className="cvccardfalcon">
          CVC
          <span className="codecvccardfalcon">
            <CardCVCElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...useOptions(this.props.fontSize)}
            />
          </span>
        </span>
      </div>
      <div className="btncreditcardfalcon" style={{textAlign: "center", marginTop:"-8%", marginLeft:"32%"}}>
        <span style={{position:"absolute", top:"95px", fontSize:"25px"}}>Your number card</span>
        <button className="btn btn-dark" style={{backgroundColor: this.props.data.color, borderColor: this.props.data.color, fontSize:"25px", borderRadius:"15px"}}>PAYMENT</button>
      </div>
      </form>
    );
  }
}

const CardForm = injectStripe(_CardForm);

const mapDispatchToProps = (dispatch) => {
  return {
      saveProfileCreditos : (uid, creditos) => dispatch(saveProfileCreditos(uid, creditos))
  }
}


export default connect(null, mapDispatchToProps)(CardForm);
