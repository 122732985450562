import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SideBar, Header} from '../pages';
import { getFirestore } from 'redux-firestore';
import { saveCreditAdminFirebase, deleteAllCreditAdminFirebase } from '../_actions';
import _ from 'lodash';

// import {loadStripe} from '@stripe/stripe-js';
// import {Elements} from '@stripe/react-stripe-js';
// import CardForm from "./CardForm";

// import "./style.css";

// const stripePromise = loadStripe('pk_test_51H8lBRAoKYak6EjVexnGOiVajnU4xQE360c8ICbk8KE40FDl5KI1cMfbAr4Ug2c7RtAlpuHWEgI1URaoIi1vu66X00jQzYxwBh');

class AdminCredits extends Component {
    constructor(props){
        super(props);

        this.state = {
            credits: []
        }
    }

    componentDidMount = () => {
        const { user, admin } = this.props;

        if(!user.uid || !admin) return false;

        this.getAllCreditsFirestore();
    }


    addclick = () => {
  
        this.setState({ 
            credits: this.state.credits.concat([{ credit: 0, price: 0}])
        })
        
    }

    handleChange = (e, i) => {
        const { name, value } = e.target;
        let { credits } = this.state;
        credits[i][name] = value;

        this.setState({
            credits
        });
    }

    saveAll = () => {
        this.props.deleteAllCreditAdminFirebase();

        this.state.credits.forEach((cred) => {
            this.props.saveCreditAdminFirebase(cred.credit, cred.price);
            this.getAllCreditsFirestore();
        });
    }

    getAllCreditsFirestore = () => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('credits')
        .collection('values').onSnapshot((querySnapshot) => {
            let cc = [];
            querySnapshot.forEach((doc) => {
                const uid = doc.id;
                const { credit, price } = doc.data();
                cc.push({
                    uid: uid,
                    credit: credit,
                    price: price
                });
            });
            this.setState({
                credits: cc
            });
        })
    }

    render() {
        const { user, admin } = this.props;

        if(!user.uid) return <Redirect to="/login" />
        if(admin === false) return <Redirect to='/' />

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminCredits</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Credits</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body">
                                                <table>
                                                    <thead>
                                                        <th>Creditos</th>
                                                        <th className="text-center">Preco</th>
                                                        {/* <th><button onClick={this.addclick} className="btn btn-primary">Novo</button> </th> */}
                                                        <th><button onClick={this.saveAll} className="btn btn-success">Guardar</button></th>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.credits.map((tab, i) => 
                                                            <tr>
                                                                <td className="text-center">
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        value={tab.credit}
                                                                        name="credit"
                                                                        onChange={(e) => this.handleChange(e, i)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        value={tab.price}
                                                                        name="price"
                                                                        onChange={(e) => this.handleChange(e, i)}
                                                                    />
                                                                </td>
                                                                {/* <td>
                                                                <button onClick={() => {
                                                                    const credits = this.state.credits;
                                                                    _.pullAt(credits, i);
                                                                    this.setState({ credits:credits})
                                                                }} className="btn btn-danger">Remove</button>
                                                                </td> */}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body">
                                                {/* <Elements stripe={stripePromise}>
                                                <div className="Demo">
                                                <CardForm />
                                                </div>
                                                </Elements>
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCreditAdminFirebase : (credit, price) => dispatch(saveCreditAdminFirebase(credit, price)),
        deleteAllCreditAdminFirebase : () => dispatch(deleteAllCreditAdminFirebase())
    }
}

const connected = connect(mapState, mapDispatchToProps)(AdminCredits);
export { connected as AdminCredits }