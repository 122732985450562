import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { alert } from './alert.reducer';

// import { reducer as firebase } from 'react-redux-firebase';
// import { reducer as firestore } from 'react-redux-firebase';

import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

export const rootReducer = combineReducers({
    authentication: authentication,
    registration: registration,
    alert: alert, 
    firestore: firestoreReducer,
    firebase: firebaseReducer
});