export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : 'none',
    ...draggableStyle
});

export const find = (arr, obj) => {
    if(arr.length == 0) return;

    const res = [];
    arr.forEach(o => {
        let match = false;
        Object.keys(obj).forEach(i => {
            if(obj[i] === o[i]){
                match = true;
            }
        });
        if(match){
            res.push(o);
        }
    });

    return res;
}