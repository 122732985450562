import React, { useMemo, useState } from "react";

const TablePrice = ({prices, handlePurchase, handleClosePurchase, pursh}) => {


    return (
        <div className="row justify-content-md-center">
            {prices.map((pric, index) => 
                <div className="col-md-3">
                    <div className="pricing-table" style={{filter: (pursh == - 1 || pursh == index) ? "brightness(100%)" : "brightness(65%)"}}>
                        <div className="ptable-single">
                            <div className="ptable-footer" style={{backgroundColor: pric.color}}>
                                <div className="ptable-action">
                                    <span><img src={"img/dist/"+pric.img} /></span>
                                </div>
                            </div>

                            <div className="ptable-footer" style={{backgroundColor: pric.color, filter:"brightness(85%)", paddingBottom: "20px"}}>
                                <div className="ptable-price">
                                    <h2>{pric.credits}<span>/ CREDITS</span></h2>
                                </div>
                            </div>

                            <div className="ptable-body">
                                <div className="ptable-description">
                                    <ul style={{display: pursh == index ? "none" : "block"}}>
                                        <li>{pric.description}</li>
                                        <li>{pric.price}€</li>
                                    </ul>
                                </div>
                            </div>

                            <div className={"ptable-header "+(pursh == index ? "select" : "")} style={{backgroundColor: pric.color, cursor: pursh == index ? "auto" : "pointer"}} onClick={() => handlePurchase(index) }>
                                {pursh == index ?
                                <span className="closebtn" onClick={() => handleClosePurchase()}><i className="fas fa-times-circle"></i></span>
                                : <></>
                                }
                                <div className="ptable-title">
                                    <h2>{pursh == index ? "Select" : "Purchase"}</h2>

                                    {pursh == index ?
                                        <span style={{color: "white", fontSize: "33px"}}><i className="fas fa-check"></i></span>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        
    );
};

export default TablePrice;
