import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';

import './stylemessage.css';


class SideBar extends Component{

  render() {
    const { admin, user } = this.props;

    if(!user.uid) return <Redirect to='/login' />

    const styletext = {
      'text-color': 'white'
    };

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="index.html" className="brand-link">
          <img src="img/dist/er.png" alt="" className="brand-image img-circle elevation-3" style={{opacity: .8}} />
          <span className="brand-text font-weight-light">Falcon</span>
        </a>
        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item has-treeview menu-open">
                <NavLink to="/" className="nav-link" activeClassName="active"  exact={true}>
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>Dashboard<i className="right fas "></i></p>
                </NavLink>
              </li>
              <li className="nav-item has-treeview">
                <NavLink to="/profile" className="nav-link" activeClassName="active">
                  <i className="nav-icon fas fa-user"></i>
                  <p>Profile<i className="right fas "></i></p>
                </NavLink>
              </li>
              <li className="nav-item has-treeview">
                <NavLink to="/message" className="nav-link" activeClassName="active">
                  <i className="far nav-icon fas fa-copy"></i>
                  <p>Enviar Mensagem</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink to="/emails" className="nav-link" activeClassName="active">
                  <i className="far nav-icon fas fa-envelope"></i>
                  <p>Ping Emails</p>
                </NavLink>
              </li>
              {/* <li className="nav-item has-treeview">

                <div className="nav-link" style={styletext}>
                  <i className="nav-icon fas fa-envelope"></i>
                  <p>Mensagens<i className="fas fa-angle-left right"></i></p>
                </div>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/message" className="nav-link" activeClassName="active">
                      <i className="far nav-icon fas fa-copy"></i>
                      <p>Enviar Mensagem</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a href="!#" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Opcao 1</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="!#" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Opcao 1</p>
                    </a>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item has-treeview">
                <NavLink to="/payment" className="nav-link" activeClassName="active">
                  <i className="far nav-icon fas fa-money-bill-wave"></i>
                  <p>Comprar créditos</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink to="/support" className="nav-link" activeClassName="active">
                  <i className="far nav-icon fas fa-comment"></i>
                  <p>Suporte</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink to="/heatmap" className="nav-link" activeClassName="active">
                  <i className="far nav-icon fas fa-map"></i>
                  <p>HeatMap</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink to="/feedback" className="nav-link" activeClassName="active">
                  <i className="far nav-icon fas fa-comment"></i>
                  <p>Feedback</p>
                </NavLink>
              </li>

              {/* <li className="nav-item has-treeview">
                <div className="nav-link">
                  <i className="nav-icon fas fa-money-check"></i>
                  <p>Pagamentos<i className="fas fa-angle-left right"></i></p>
                </div>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/payment" className="nav-link" activeClassName="active">
                      <i className="far nav-icon fas fa-money-bill-wave"></i>
                      <p>Comprar créditos</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a href="facturas.html" className="nav-link">
                      <i className="fas fa-receipt nav-icon"></i>
                      <p>Facturas</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="!#" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Opcao 1</p>
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item has-treeview">
                <a href="!#" className="nav-link">
                  <i className="nav-icon fas fa-table"></i>
                  <p>APIs / Modulos<i className="right"></i></p>
                </a>
              </li>
              <li className="nav-item has-treeview">
                <div className="nav-link">
                  <i className="nav-icon far fa-question-circle"></i>
                  <p>Suporte<i className="fas fa-angle-left right"></i></p>
                </div>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="chat.html" className="nav-link">
                      <i className="nav-icon far fa-comments"></i>
                      <p>Chat</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="contactos.html" className="nav-link">
                      <i className="far fa-address-card nav-icon"></i>
                      <p>Contactos</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="!#" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Opcao 1</p>
                    </a>
                  </li>
                </ul>
              </li> */}
              {(() => {
                if(admin){
                  return (

                    <>

                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/users" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Clientes</p>
                      </NavLink>
                    </li>


                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/messages" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Messages</p>
                      </NavLink>
                    </li>


                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/prices" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Preços</p>
                      </NavLink>
                    </li>


                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/credits" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Créditos</p>
                      </NavLink>
                    </li>


                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/rules" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Rules</p>
                      </NavLink>
                    </li>

                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/support" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Support</p>
                      </NavLink>
                    </li>

                    <li className="nav-item has-treeview">
                      <NavLink to="/admin/feedback" className="nav-link" activeClassName="active">
                        <i className="far fa-circle nav-icon"></i>
                        <p>(Admin) Feedback</p>
                      </NavLink>
                    </li>

                    {/* <li className="nav-item has-treeview">
                      <div className="nav-link">
                        <i className="nav-icon far fa-question-circle"></i>
                        <p>Administrator<i className="fas fa-angle-left right"></i></p>
                      </div>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <NavLink to="/admin/users" className="nav-link" activeClassName="active">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Clientes</p>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin/messages" className="nav-link" activeClassName="active">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Mensagens</p>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/admin/prices" className="nav-link" activeClassName="active">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Preços</p>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/admin/credits" className="nav-link" activeClassName="active">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Creditos</p>
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/admin/rules" className="nav-link" activeClassName="active">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Rules</p>
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    </>
                  );
                }
              })()}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

 const mapState = (state) => {
   return {
     admin: state.firebase.profile.admin,
     user: state.firebase.auth
   }
 }

 const connected = connect(mapState, {})(SideBar);
 export { connected  as SideBar };
