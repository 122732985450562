import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, SideBar } from './';
import { saveProfileName } from '../_actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getFirestore } from 'redux-firestore';
import { reorder, getItemStyle } from '../_helpers';
import { deleteAllRulesUserFirbase, saveRuleUserFirebase, deleteAllAndSaveRulesUserFirbase } from '../_actions';

import { data } from 'jquery';

class Profile extends Component {

    constructor(props){
        super(props);

        this.state = {
            name: '', 
            x: 1,
            dataNumbers: []
        }
    }
    
    componentDidMount() {
        if(!this.props.user.uid) return false;
        this.getNumberPhones();
    }

    getNumberPhones = () => {
        this.setState({
            dataNumbers: Array()
        });

        const firestore = getFirestore();
        firestore.collection('users').doc(this.props.user.uid)
        .collection('rules').onSnapshot((docs) => {
            let cc = [];
            docs.forEach((doc) => {
                const uid = doc.id;
                const { order, areaCode, numberphone, smsdisponiveis, zonasms } = doc.data();
                cc.push({
                    id: order,
                    areaCode,
                    numberphone,
                    smsdisponiveis,
                    zonasms
                });
            });

            cc.sort((a,b) => parseInt(a.id) - parseInt(b.id));

            this.setState({
                dataNumbers: cc
            });
        });
    }

    onDragEnd = (result) => {
        if(!result.destination){
            return;
        }

        let dataNumbers = reorder(
            this.state.dataNumbers,
            result.source.index,
            result.destination.index
        );

        dataNumbers.forEach((d, index) => {
            d.id = index+1;
        });

        this.refreshTable(dataNumbers);

        this.setState({
            dataNumbers
        });
    }

    refreshTable = (dataArray) => {
        this.props.deleteAllAndSaveRulesUserFirbase(this.props.user.uid, dataArray);

        this.getNumberPhones();
    }

    saveDeleteNumber = (rule_uid) => {
        console.log(rule_uid);

        const { dataNumbers } = this.state;

        let a = dataNumbers.filter(d => {
            return parseInt(rule_uid) != parseInt(d.id);
        });

        a.forEach((aux, i) => {
            aux.id = i+1
        });

        this.refreshTable(a);
    }

    handleChangeName = (e) => {
        this.setState({
            name: e.target.value,
            x: 2
        });
    }

    submitName = (e) => {
        e.preventDefault();

        const { name } = this.state;
        console.log("uid: "+this.props.user.uid+" name: "+name);
        if(name && name != this.props.profile.name){
            this.props.saveProfileName(this.props.user.uid, name);
        }
    }

 
    render() {
       const { user, profile } = this.props;

       if(!user.uid) return <Redirect to='/login' />
        
       let list = [];

       this.state.dataNumbers.forEach((d) => {
           let symbol = d.areaCode != "" ? "+" : "";
           list.push({
               id: d.id,
               numberphone: symbol+""+d.areaCode+""+d.numberphone,
               smsdisponiveis: d.smsdisponiveis,
               flag: d.zonasms.flag,
               name: d.zonasms.name
           });
       });

       console.log(list);

        if(this.state.x == 1){
            this.state.name = profile.name;
        }

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Profile</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">User Profile</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div id="settings">
                                                        <form className="form-horizontal">
                                                            <div className="form-group row">
                                                                <label for="inputName" className="col-sm-2 col-form-label">Name</label>
                                                                <div className="col-sm-8">
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        name="name"
                                                                        defaultValue={profile.name}
                                                                        value={this.state.name} 
                                                                        placeholder="Name"
                                                                        onChange={this.handleChangeName} />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <button type="button" onClick={this.submitName} className="btn btn-danger">Save</button>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label for="inputEmail" className="col-sm-2 col-form-label">Email</label>
                                                                <div className="col-sm-7">
                                                                    <span  className="col-form-label">{user.email}</span>
                                                                </div>
                                                                <div className="col-sm-3" >
                                                                    {user.emailVerified ? <div className="bg-success">Verificado</div> : <div className="bg-danger">Nao verficado</div>}
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label for="inputName2" className="col-sm-2 col-form-label">Telemovel</label>
                                                                <div className="col-sm-10">
                                                                    <span>{user.phoneNumber != null ? user.phoneNumber : <img width="72" src="https://d3fldh011ywsk8.cloudfront.net/wp-content/uploads/2020/03/Android-app-on-Google-play-logo-vector-2.png" className="img-circle elevation-2" />}</span>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div id="settings">
                                                        <div className="table-responsive mailbox-messages">
                                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                                <Droppable droppableId="droppable">
                                                                    {(provided, snapshot) => (
                                                                        <table className="table table-hover table-striped" ref={provided.innerRef}>
                                                                            <thead>
                                                                                <td>ID</td>
                                                                                <td>Numero</td>
                                                                                <td>SMS disponiveis</td>
                                                                                <td>Zona</td>
                                                                                <td>Acções</td>
                                                                            </thead>
                                                                            <tbody>
                                                                                {list.map((numb, index) => (
                                                                                    <Draggable key={index} draggableId={(index+1).toString()} index={index}>
                                                                                        {(provided, snapshot) => (
                                                                                            <tr
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps.style
                                                                                                )}
                                                                                            >                                                                                                
                                                                                                <td className="mailbox-subject">{numb.id}</td>
                                                                                                <td className="mailbox-subject">{numb.numberphone}</td>
                                                                                                <td className="mailbox-subject">{numb.smsdisponiveis == -1 ? <i class="fas fa-infinity"></i> : numb.smsdisponiveis}</td>
                                                                                                <td className="mailbox-subject"><img style={{height: '35px'}} src={numb.flag} /> <span>{numb.name}</span></td>
                                                                                                <td className="mailbox-subject">
                                                                                                    {numb.numberphone == 'falcon' ?
                                                                                                    <></>
                                                                                                    :
                                                                                                    <button key={numb.id} className="btn btn-danger" onClick={() => {this.saveDeleteNumber(numb.id)}}>Eliminar</button>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                       

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        profile: state.firebase.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveProfileName : (uid, name) => dispatch(saveProfileName(uid, name)),
        saveRuleUserFirebase : (uid, data) => dispatch(saveRuleUserFirebase(uid, data)),
        deleteAllRulesUserFirbase : (uid) => dispatch(deleteAllRulesUserFirbase(uid)),
        deleteAllAndSaveRulesUserFirbase : (uid, data) => dispatch(deleteAllAndSaveRulesUserFirbase(uid, data))
    }
}

const connected = connect(mapStateToProps, mapDispatchToProps)(Profile);
export { connected as Profile };