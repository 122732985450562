import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { forgot } from '../_actions';

class Forgot extends Component {

    constructor(props){
        super(props);

        this.state = {
            email: ''
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { email } = this.state;

        if(email){
            this.props.forgot(email);
        }

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }


    render() {
        const { auth, authError } = this.props;
        const { email } = this.state;
        return (
            <div className="login-page">
                {alert.message &&
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
            
                <div className="login-box">
                    <div className="login-logo"><a href="../../index2.html"><b>Falcon</b></a></div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            { authError ? 
                            <div>
                                <p>{authError}</p> 
                                <p className=""><Link onClick={this.forceUpdate} to="/login">Login</Link></p>
                            </div>
                            : 
                            
                            <div>
                            <p className="login-box-msg">Esqueceu a sua senha? Aqui pode recuperar uma nova password.</p>

          
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Email" 
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Solicitar uma nova password</button>
                                    </div>
                                </div>
                            </form>
          
                            <p className="mt-3 mb-1"><Link to="/login">Login</Link></p>
                            <p className="mb-0"><Link to="/register" className="text-center">Não tenho uma conta</Link></p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        authError: state.authentication.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        forgot: (email) => dispatch(forgot(email))
    }
}

const connected = connect(mapState, mapDispatchToProps)(Forgot);
export { connected as Forgot };