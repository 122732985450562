import React, { Component, useRef, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, SideBar } from './';
import Select2 from 'react-select2-wrapper';
import { csvTojson } from '../_helpers';
import validator from 'validator';
import axios from 'axios';
import { CSVLink, CSVDownload } from 'react-csv';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import { saveProfileCreditos } from '../_actions';
import { Pagination, RadialSeparators } from '../_components';
import ReactPaginate from 'react-paginate';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TableEmails from './utils/TableEmails';

class Emails extends Component {
    constructor(props) {
        super(props);

        this.csvUpload = React.createRef();

        this.state = {
            novo_email: '',
            emails_list: [],
            checkedItems: [],
            table: [],
            // tablepage: [],
            csvReport: {
                data: [],
                headers: [],
                filename: ''
            },
            price: 0,
            currentPage: 1,
            totalPages: 7,
            pageCount: -1,
            pageLimit: null,
            currentTab: 0,
            customform1: null,
            filename: '',
            btnPPS: 'play',
            isRunning: false,
            indexCountTable: 0
        };

        this.tabForm = React.createRef();
        this.stepForm = React.createRef();
        this.stepForm.current = [];
        this.tabForm.current = [];
    }

    componentDidMount = () => {
        if(!this.props.auth.uid) return false;
        this.getPriceEmailAdmin();
    }

    getPriceEmailAdmin = () => {
        const firestore = getFirestore();
        firestore.collection('admin').doc('prices').collection('emails').doc('values')
        .get().then(doc => {
            if(doc.exists){
                const { price } = doc.data();
                this.setState({
                    price: price
                });
            }
        })
    }

    handleClickFile = e => {
        this.csvUpload.current.click();
    }

    handleChangeCSV = e => {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsText(files[0]);
        console.log(files[0].name);
        reader.onload = e => {
            let json = this.props.csvTojson(e.target.result, ';');
            let arr = [];
            json.forEach(n => {
                if(validator.isEmail(n)){
                    arr.push(n);
                }
            });

            this.setState({
                emails_list: arr,
                filename: files[0].name,
                currentTab: 2
            });
        };
    }

    handleChange = e => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });

    }

    handleSend = e => {
        e.preventDefault();
        console.log(this.state.emails_list.length);
        if(!this.state.emails_list.length > 0)
            return;

        let x = 0;
        let arr = this.state.table;

        let total = this.state.price * this.state.emails_list.length;

        if(total > this.props.profile.creditos)
            return;

        this.props.saveProfileCreditos(this.props.auth.uid, (this.props.profile.creditos - total));

        this.state.emails_list.forEach(email => {
            x++;
            arr.push({
                id: x,
                email: email,
                status: "<div class='loader'></div>"
            });
        });


        this.setState({
            table: arr,
            pageCount: Math.ceil(arr.length / this.state.totalPages)
        });

        const url = "https://falcon-g1ii.onrender.com/api/public/pingemails";


        this.cancelTabCSV();

        const { isRunning } = this.state;

    }


    handleChangeSelect = e => {
        e.preventDefault();
        let emails = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({
            emails_list: emails
        });
    }

    sendEmailToSelect = e => {
        if(e.key === 'Enter'){
            const { novo_email, emails_list } = this.state;
            const novos = novo_email.split(/;| |,/);
            let arr = emails_list;

            novos.forEach(novo => {
                if(validator.isEmail(novo)){
                    arr.push(novo);
                }
            })

            this.setState({
                emails_list: arr,
                novo_email: ''
            });
        }

    }

    clickDropdown = what => {
        console.log(what);

        if(what == 'all'){
            this.selectChecboxAll(true);
        }else{
            this.selectCheck(what);
        }
    }



    addTabToRefs = el => {
        if(el && !this.tabForm.current.includes(el)){
            this.tabForm.current.push(el);
        }
    }

    addStepToRefs = el => {
        if(el && !this.stepForm.current.includes(el)){
            this.stepForm.current.push(el);
        }
    }

    customformChange = e => {
        this.setState({
            customform1: e.target.name
        });
    }

    handleSendForm1 = e => {
        // e.preventDefault();

        const { customform1 } = this.state;

        if(customform1 === 'radiocsv'){
            this.csvUpload.current.click();
        }else if(customform1 === 'radiocp'){
            this.setState({
                currentTab: 1
            });
        }
    }

    cancelTabCSV = () => {
        this.setState({
            currentTab: 0,
            emails_list: [],
            filename: '',
            customform1: null
        });
    }

    handlePageClick = data => {
        console.log(data.selected);

        this.setState({
            currentPage: data.selected + 1
        });
    }

    getResultEmails = () => {
        const { table } = this.state;
        let count = 0;
        table.forEach((email, index) => {
            if(!email.status.includes("loader")){
                count += 1;
            }
        });

        return count;
    }

    render = () => {
        const { novo_email, emails_list, table, currentPage, totalPages, pageLimit, currentTab, customform1, btnPPS } = this.state;
        if(!this.props.auth.uid) return <Redirect to='/login' />
        const indexOfLast = currentPage * totalPages;
        const indexOfFirst = indexOfLast - totalPages;
        const tablepage = table.slice(indexOfFirst, indexOfLast);
        const visibilitypage = (currentPage * totalPages) > table.length ? table.length : (currentPage * totalPages);
        const resultemails = this.getResultEmails();

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />
                    <div className="content-wrapper">
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    {parseFloat(this.state.price) > 0
                                    ?
                                    <>
                                        <div className="col-md-5">
                                            <div id="regForm">
                                                <div className="tabForm" style={{display: (currentTab == 0 ? 'block' : 'none')}}>
                                                    <h3 style={{"margin-bottom":"30px", "fontWeight":"bold"}}>How would you like to import your contacts?</h3>
                                                    <form name="form" >
                                                        <div className="custom-control custom-radio p-2">
                                                            <input type="radio" id="radiocsv" checked={customform1 === 'radiocsv'} onChange={this.customformChange} name="radiocsv" className="custom-control-input" />
                                                            <label className="custom-control-label labelradio" for="radiocsv">Insert CSV <br /> <span className="spanlabel">Upload a CSV</span></label>
                                                        </div>

                                                        <div className="custom-control custom-radio p-2">
                                                            <input type="radio" id="radiocp" checked={customform1 === 'radiocp'} onChange={this.customformChange} name="radiocp" className="custom-control-input radio4" />
                                                            <label className="custom-control-label labelradio" for="radiocp">Copy and Paste <br /> <span className="spanlabel">Copy and paste your contacts</span></label>
                                                            <input
                                                                className="form-control"
                                                                name="csv"
                                                                type="file"
                                                                ref={this.csvUpload}
                                                                style={{display: 'none'}}
                                                                onChange={this.handleChangeCSV}
                                                            />
                                                        </div>
                                                        <div className="text-center p-3">
                                                            <button type="button" onClick={this.handleSendForm1} className="btn btn-default rounded-pill btnfalc">Upload</button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="tabForm" style={{display: (currentTab == 1 ? 'block' : 'none')}}>
                                                    <div className="form-group select2-primary" style={{borderStyle:'groove', height: '312px'}}>
                                                        <Select2
                                                            className="select2"
                                                            multiple
                                                            style={{width: '100%', "border":"none"}}
                                                            data={emails_list}
                                                            value={emails_list}
                                                            onChange={this.handleChangeSelect}
                                                            options=
                                                            {{
                                                                tags: false,
                                                                allowClear: false,
                                                                minimumResultsForSearch: -1,
                                                                scrollAfterSelect: true
                                                            }}
                                                        />

                                                        <div className="form-group" style={{"marginTop":"-10px"}}>
                                                            <input
                                                                className="form-control"
                                                                name="novo_email"
                                                                style={{"border":"none", "marginBottom":"-15px"}}
                                                                value={novo_email}
                                                                placeHolder="Write e-mail..."
                                                                onChange={this.handleChange}
                                                                onKeyDown={(e) => this.sendEmailToSelect(e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="text-center p-3">
                                                        <button type="button" onClick={this.handleSend} className="btn btn-default rounded-pill btnfalc">Verify</button>
                                                    </div>
                                                </div>

                                                <div className="tabForm" style={{display: (currentTab == 2 ? 'block' : 'none')}}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <span style={{"fontWeight":"bold"}}>{this.state.filename}</span><br/>
                                                            <span>{this.state.emails_list.length}&nbsp; emails</span>
                                                            <div className="float-right" style={{"margin": "-15px 35px"}}>
                                                                <button   onClick={this.cancelTabCSV} className="btn btn-primary btn-circle"><i className="fa fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="text-center p-3">
                                                        <button type="button" onClick={this.handleSend} className="btn btn-default rounded-pill btnfalc">Verify</button>
                                                    </div>
                                                </div>

                                                <div style={{"margin": "0 150px"}}>
                                                    <span className="stepForm" style={{"background-color":(currentTab == 0 ? "#362544" : "#bbbbbb")}}></span>
                                                    <span className="stepForm" style={{"background-color":(currentTab > 0 ? "#362544" : "#bbbbbb")}}></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-7">
                                            {this.state.table.length > 0 ?

                                                <TableEmails emails={table}
                                                onCheckStop = {() => this.setState({ table: [] })}    />

                                            : <></>  }

                                        </div>
                                    </>
                                    :
                                    <div className="text-center">
                                        <img width="572" src="https://www.seekpng.com/png/full/990-9902766_keep-calm-its-not-over-yet-poster-not.png" className="img-circle elevation-2" />
                                    </div>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        datas: state.datas,
        profile: state.firebase.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        csvTojson : (csv, split) => csvTojson(csv, split),
        saveProfileCreditos: (uid, credits) => dispatch(saveProfileCreditos(uid, credits))
    };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Emails);
export { connected as Emails };
