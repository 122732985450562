import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SideBar, Header} from '../pages';

class AdminSupport extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { user, admin } = this.props;
        if(!user.uid) return <Redirect to='/login' />
        if(admin == false) return <Redirect to='/' />

        return(
            <div className='sidebar-mini layout-fixed' style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminSupport</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Support</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-primary card-outline">
                                            <div className="card-header">
                                                <h3 className="card-title">Support</h3>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="mailbox-controls">
                                                    <div></div>
                                                </div>
                                                <div className="table-responsive mailbox-messages">
                                                    <table className="table table-hover table-striped"></table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const connected = connect(mapState, {})(AdminSupport);
export { connected as AdminSupport}