import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SideBar, Header } from '../pages';
import { Modal } from 'react-bootstrap';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import { Textbox } from "react-inputs-validation";
import validator from 'validator';
import { getFirestore } from 'redux-firestore';
import datacsv from '../_helpers/numbers-countries.csv';
import { readRemoteFile } from 'react-papaparse';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { deleteAllRulesAdminFirebase, saveRuleAdminFirebase, deleteAllAndSaveRulesAdminFirebase } from '../_actions';
import { reorder, getItemStyle, find } from '../_helpers';
import moment from "moment";


class AdminRules extends Component {
    constructor(props){
        super(props);

        this.state = {
                showModal: false,
                id: 0,
                numberphone: '',
                smsdisponiveis: '',
                smsrenovaveis: '',
                diasrenovaveis: '',
                diarenovavel: '',
                zonasms: '',
                dataNumbers: Array(),
                FLAG_SELECTOR_OPTION_LIST: Array(),
                FLAG_SELECTOR_OPTION_LIST2: Array(),
                areaCode: "0",
                areaCode2: "0",
                validate: false,
                hasPhoneError: true,
                zona: {},
                buttonEdit: false,
        };



        this.saveEditNumber = this.saveEditNumber.bind(this);
    }

    componentDidMount() {
        const { user, admin } = this.props;

        if(!user.uid || !admin) return false;
        
        this.getCodeCountries();
        this.getMessagesByNumbers();
        this.getNumberPhones();
    }

    getCodeCountries = () => {
        const firestore = getFirestore();
        this.ref = firestore.collection('admin').doc('prices')
                .collection('countries').onSnapshot((docs) => {
                    let data = [];
                    docs.forEach((doc) => {
                        const codef = doc.id;
                        const { indicador } = doc.data();
                        data.push({
                            code: codef,
                            number:indicador
                        });
                    });
                    this.saveCountries(data);
                });
    }

    saveCountries = (countries) => {
        readRemoteFile(datacsv, {
            complete: (results) => {
                let arr = [];
                let arr2 = [];
                results.data.forEach((d) => {
                    countries.forEach((c) => {
                        if(d[3].toLowerCase() == c.code.toLowerCase()){
                            arr.push({
                                id: d[5].toString(),
                                name: d[0],
                                displayText: d[3].toString()+"("+d[5].toString()+")",
                                locale: d[6],
                                flag: 'http://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/'+d[3].toLowerCase()+'.svg'
                            });

                            arr2.push({
                                id: d[5].toString(),
                                name: d[0],
                                displayText: d[3].toString()+" ("+d[0].toString()+")",
                                locale: d[6],
                                flag: 'http://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/'+d[3].toLowerCase()+'.svg'
                            });
                        }
                    });
                });

                this.setState({
                    FLAG_SELECTOR_OPTION_LIST: arr,
                    FLAG_SELECTOR_OPTION_LIST2: arr2,
                    areaCode: arr[0].id,
                    areaCode2: arr2[0].id
                })
            }
        })  
    }

    getMessagesByNumbers = () => {
        //firebase
    }

    getNumberPhones = () => {
        this.setState({
            dataNumbers: Array()
        })
        console.log("getNumberPhones");
        const firestore = getFirestore();
        firestore.collection('admin').doc('rules')
        .collection('values').onSnapshot((querySnapshot) => {
            let cc = [];
            querySnapshot.forEach((doc) => {
                const uid = doc.id;
                const { order, areaCode, numberphone, smsdisponiveis, zonasms, smsrenovaveis, diasrenovaveis, diarenovavel } = doc.data();
                
                cc.push({
                    id: order,
                    areaCode,
                    numberphone,
                    smsdisponiveis, 
                    zonasms,
                    smsrenovaveis,
                    diasrenovaveis,
                    diarenovavel
                });
            });

            
            cc.sort((a, b) => parseInt(a.id) - parseInt(b.id));

            console.log(cc);
            this.setState({
                dataNumbers: cc
            });
        })
    }

    onDragEnd = (result) => {
        if(!result.destination){
            return;
        }

        let dataNumbers = reorder(
            this.state.dataNumbers,
            result.source.index,
            result.destination.index
        );

        dataNumbers.forEach((d, index) => {
            d.id = index+1;
        });

        this.refreshTable(dataNumbers);

        this.setState({
            dataNumbers
        });
    }

    handleCloseModal = () => {
        this.setState({
            id: 0,
            numberphone: '',
            smsdisponiveis: '',
            smsrenovaveis: '',
            diasrenovaveis: '',
            diarenovavel: '',
            zonasms: '',
            showModal: false
        })
    }

    handleOpenModal = () => {
        this.setState({
            showModal: true
        });
    }

    saveNewNumber = (e) => {
        e.preventDefault();
        
        const { numberphone, smsdisponiveis, smsrenovaveis, diasrenovaveis, diarenovavel, hasPhoneError, id, zonasms, areaCode2, areaCode,  } = this.state;
        const numb = "+"+areaCode+""+numberphone;
        const date_now = moment().format("DD-MM-YYYY");

        let findNumb = false;
        let arrayNumbers = this.state.dataNumbers;

        arrayNumbers.forEach((d) => {
            if("+"+d.areaCode+""+d.numberphone == numb){
                findNumb = true;
                return;
            }
        });

        if(findNumb && !parseInt(id) > 0){
            console.log("findNumb");
            return;
        }

        let codErr = false;

        this.state.FLAG_SELECTOR_OPTION_LIST.forEach((f) => {
            if(parseInt(f.id) == parseInt(areaCode)){
                if(!validator.isMobilePhone(numb, f.locale)){
                    codErr = true;
                }

                return;
            }
        });

        if(codErr){
            this.setState({
                hasPhoneError: true
            });
            console.log("codErr");
            return;
        }

        let obj = {};

        this.state.FLAG_SELECTOR_OPTION_LIST2.forEach((f) => {
            if(parseInt(f.id) == parseInt(areaCode2)){
                obj = f;
                return;
            }
        });

        if(parseInt(id) > 0){
            const index = arrayNumbers.findIndex(o => parseInt(o.id) === parseInt(id));

            arrayNumbers[index].numberphone = numberphone;
            arrayNumbers[index].smsdisponiveis = smsdisponiveis;
            arrayNumbers[index].zonasms = obj;
            arrayNumbers[index].areaCode = areaCode;
            arrayNumbers[index].smsrenovaveis = smsrenovaveis;
            arrayNumbers[index].diasrenovaveis = diasrenovaveis;
            arrayNumbers[index].diarenovavel = diarenovavel;
            arrayNumbers[index].date_upd = date_now;
        }else{
            arrayNumbers.push({
                id: arrayNumbers.length + 1,
                numberphone: numberphone,
                smsdisponiveis: smsdisponiveis,
                zonasms: obj,
                areaCode: areaCode,
                smsrenovaveis: smsrenovaveis, 
                diasrenovaveis: diasrenovaveis,
                diarenovavel: diarenovavel,
                date_new: date_now,
                date_upd: date_now
            });
        }

        this.refreshTable(arrayNumbers);
    }

    refreshTable = (arrayNumbers) => {
        this.props.deleteAllAndSaveRulesAdminFirebase(arrayNumbers);
       
        this.getNumberPhones();

        this.setState({
            id: 0,
            numberphone: '',
            smsdisponiveis: '',
            zonasms: '',
            smsrenovaveis: '', 
            diasrenovaveis: '',
            diarenovavel: '',
            areaCode: this.state.FLAG_SELECTOR_OPTION_LIST[0].id,
            areaCode2: this.state.FLAG_SELECTOR_OPTION_LIST2[0].id,
            showModal: false
        })
    }

    findCountry = (code) => {
        return readRemoteFile(datacsv, {
            complete: (results) => {
                let obj = {}; 

                results.data.forEach((d) => {
                        if(parseInt(d[5]) == parseInt(code)){
                            obj = {
                                name: d[0],
                                flag: 'http://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/'+d[3].toLowerCase()+'.svg'
                            }
                            return;
                        }
                });


                this.setState({
                    zona: obj
                })

                return obj;
            }
        }) 

    }

    saveEditNumber = (id) => {
        const { dataNumbers } = this.state;
        let a =  this.findArray(dataNumbers, id);

        if(a == null){
            return;
        }

        const { numberphone, smsdisponiveis, zonasms, areaCode, smsrenovaveis, diasrenovaveis, diarenovavel } = a;

        this.setState({
            showModal: true,
            numberphone: numberphone,
            smsdisponiveis: smsdisponiveis,
            zonasms: zonasms,
            areaCode2: zonasms.id,
            areaCode: areaCode,
            smsrenovaveis: smsrenovaveis,
            diasrenovaveis: diasrenovaveis,
            diarenovavel: diarenovavel,
            id: id,
            buttonEdit: true
        });
    }

    findArray = (array, id) => {
        let fa = {};
        let find = false;

        array.forEach((a) => {
            if(parseInt(a.id) == parseInt(id)){
                fa = a;
                find = true;
                return;
            }
        });

        return find ? fa : null;
    }

    saveDeleteNumber = (id) => {
        const { dataNumbers } = this.state;

        let a = dataNumbers.filter((d) => {
            return parseInt(id) != parseInt(d.id);
        });

        a.forEach((aux, index) => {
            aux.id = index+1;
        });

        this.refreshTable(a);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    render() {
        const { user, admin } = this.props;

        if(!user.uid) return <Redirect to='/login' />
        if(admin == false) return <Redirect to='/' />

        const { numberphone, validate, areaCode, smsdisponiveis, smsrenovaveis, diasrenovaveis, diarenovavel, zonasms, id, FLAG_SELECTOR_OPTION_LIST, FLAG_SELECTOR_OPTION_LIST2, areaCode2, hasPhoneError } = this.state;
        const currentItem = FLAG_SELECTOR_OPTION_LIST.length > 0 ? find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0] : null;
        const currentItem2 = FLAG_SELECTOR_OPTION_LIST2.length > 0 ? find(FLAG_SELECTOR_OPTION_LIST2, { id: areaCode2 })[0] : null;
        
        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>AdminRules</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Admin</a></li>
                                            <li className="breadcrumb-item active">Rules</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body">

                                                <button className="btn btn-success" onClick={this.handleOpenModal}>Novo numero</button>

                                                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Novo numero para envio</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        {currentItem == null ? <></> :
                                                        <form>
                                                            <input type="hidden" value={id} name="id" />
                                                            <div className="form-group">
                                                                <label for="rules-numero" className="col-form-label">Numero:</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '0',
                                                                        height: '45px',
                                                                        width: '100px'
                                                                    }}>
                                                                    <ReactCustomFlagSelect 
                                                                        tabIndex={"1"}
                                                                        id={"areaCode"}
                                                                        name={"areaCode"}
                                                                        value={currentItem.id}
                                                                        disabled={false}
                                                                        showSearch={false}
                                                                        showArrow={true}
                                                                        animate={true}
                                                                        optionList={FLAG_SELECTOR_OPTION_LIST}
                                                                        classNameWrapper="" //Optional.[String].Default: "".
                                                                            classNameContainer="" //Optional.[String].Default: "".
                                                                            classNameOptionListContainer="" //Optional.[String].Default: "".
                                                                            classNameOptionListItem="" //Optional.[String].Default: "".
                                                                            classNameDropdownIconOptionListItem={""} //Optional.[String].Default: "".
                                                                            customStyleWrapper={{}} //Optional.[Object].Default: {}.
                                                                        customStyleContainer={{ border: "none", fontSize: "12px "}}
                                                                        customSyleSelect={{ width: "8px"}}
                                                                        CustomStyleOptionListContainer={{
                                                                            maxHeight: "40px",
                                                                            overflow: "auto",
                                                                            width: "120px"
                                                                        }}
                                                                        customStyleOptionListItem={{}} //Optional.[Object].Default: {}.
                                                                        onChange={areaCode =>{
                                                                            this.setState({
                                                                                areaCode: areaCode,
                                                                                numberphone: numberphone
                                                                            });
                                                                        }}
                                                                    />
                                                                    
                                                                </div>

                                                                <Textbox 
                                                                    attributesInput={{
                                                                        name: "numberphone",
                                                                        type: "text",
                                                                        id: 'rules-numero',
                                                                        class: 'form-control'
                                                                    }}
                                                                    customSyleWrapper={{ height: "100%" }}
                                                                    customStyleContainer={{ height: "100%" }}
                                                                    customStyleInput={{
                                                                        paddingTop: "0",
                                                                        paddingBottom: "0",
                                                                        height: "45px",
                                                                        paddingLeft: "120px",
                                                                        paddingRight: "20px"
                                                                    }}
                                                                    value={numberphone}
                                                                    validate={validate}
                                                                    validateCallback={res =>
                                                                        this.setState({
                                                                            hasPhoneError: res,
                                                                            validate: false
                                                                        })
                                                                    }
                                                                    type="text"
                                                                    onChange={res => {
                                                                        this.setState({
                                                                            numberphone: res
                                                                        }) 
                                                                    }}
                                                                    onBlur={() => {}}
                                                                    validationOption={{
                                                                        check: true,
                                                                        required: true,
                                                                        customFunc: phone => {
                                                                            if(validator.isMobilePhone(`${numberphone}`, currentItem.locale)){
                                                                                return true;
                                                                            }else{
                                                                                return `Telemovel invalido para ${currentItem.locale}`;
                                                                            }
                                                                        }
                                                                    }}

                                                                />
                                                                </div>
                                                                {/* <input type="text" value={numberphone} name="numberphone" className="form-control" id="rules-numbero" onChange={this.handleChange} /> */}
                                                            </div>
                                                            <div className="input-group">
                                                            <div className="form-group">
                                                                <label for="smsdisponiveis" className="col-form-label">Numero de SMS Disponiveis:</label>
                                                                <input type="text" value={smsdisponiveis} name="smsdisponiveis" className="form-control" id="smsdisponiveis" onChange={this.handleChange} />
                                                            </div>

                                                            

                                                            <div className="form-group">
                                                                <label for="rules-zona" className="col-form-label">Zona de envio:</label>
                                                                {/* <input type="text" value={zonasms} name="zonasms" className="form-control" id="rules-zona" onChange={this.handleChange} /> */}
                                                                <div style={{ position: 'relative'}}>
                                                                    <div style={{ position: 'absolute', left: '0', height: '45px', width: '100px', padding: '5px 0px 0px 25px' }}> 
                                                                        <ReactCustomFlagSelect 
                                                                            tabIndex={"1"}
                                                                            id={"areaCode2"}
                                                                            name={"areaCode2"}
                                                                            value={currentItem2.id}
                                                                            disabled={false}
                                                                            showSearch={false}
                                                                            showArrow={true}
                                                                            animate={true}
                                                                            optionList={FLAG_SELECTOR_OPTION_LIST2}
                                                                            classNameWrapper="" //Optional.[String].Default: "".
                                                                                classNameContainer="" //Optional.[String].Default: "".
                                                                                classNameOptionListContainer="" //Optional.[String].Default: "".
                                                                                classNameOptionListItem="" //Optional.[String].Default: "".
                                                                                classNameDropdownIconOptionListItem={""} //Optional.[String].Default: "".
                                                                                customStyleWrapper={{}} //Optional.[Object].Default: {}.
                                                                            customStyleContainer={{ border: "none", fontSize: "12px "}}
                                                                            customSyleSelect={{ width: "80px"}}
                                                                            CustomStyleOptionListContainer={{
                                                                                maxHeight: "40px",
                                                                                overflow: "auto",
                                                                                width: "120px"
                                                                            }}
                                                                            customStyleOptionListItem={{}} //Optional.[Object].Default: {}.
                                                                            onChange={areaCode2 =>{
                                                                                this.setState({
                                                                                    areaCode2: areaCode2,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label for="smsrenovaveis" className="col-form-label">Numero de SMS Renovaveis:</label>
                                                                <input type="text" value={smsrenovaveis} name="smsrenovaveis" className="form-control" id="smsrenovaveis" onChange={this.handleChange} />
                                                            </div>
                                                            
                                                            <div className="form-group">
                                                                <label for="diasrenovaveis" className="col-form-label">Dias a renovar</label>
                                                                <input type="text" value={diasrenovaveis} name="diasrenovaveis" className="form-control" id="diasrenovaveis" onChange={this.handleChange} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label for="diarenovavel" className="col-form-label">Dia a começar a renovar</label>
                                                                <input type="text" value={diarenovavel} name="diarenovavel" className="form-control" id="diarenovavel" onChange={this.handleChange} />
                                                            </div>

                                                            </div>
                                                        </form>
                                                            }
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className="btn btn-secundary" onClick={this.handleCloseModal}>Cancelar</button>
                                                        <button className="btn btn-primary" onClick={this.saveNewNumber}>Guardar</button>
                                                    </Modal.Footer>
                                                </Modal>

                                                <div className="table-responsive mailbox-messages">
                                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <table className="table table-hover table-striped" ref={provided.innerRef}>
                                                                    <thead>
                                                                        <td>ID</td>
                                                                        <td>Numero</td>
                                                                        <td>SMS disponiveis</td>
                                                                        <td>Zona</td>
                                                                        <td>Acções</td>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.dataNumbers.map((numb, index) => (
                                                                            <Draggable key={index} draggableId={(index+1).toString()} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                <tr
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getItemStyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                        )}
                                                                                >
                                                                                    <td className="mailbox-subject">{numb.id.toString()}</td>
                                                                                    <td className="mailbox-subject">{"+"+numb.areaCode+""+numb.numberphone}</td>
                                                                                    <td className="mailbox-subject">{numb.smsdisponiveis}</td>
                                                                                    <td className="mailbox-subject"><img style={{height: '35px'}} src={numb.zonasms.flag} /> <span>{numb.zonasms.name}</span></td>
                                                                                    <td className="mailbox-subject">
                                                                                        <button className="btn btn-warning" onClick={() => {this.saveEditNumber(numb.id)}} >Editar</button>
                                                                                        <button key={numb.id} className="btn btn-danger" onClick={() => {this.saveDeleteNumber(numb.id)}}>Eliminar</button>
                                                                                    </td>
                                                                                </tr>
                                                                                )}
                                                                            </Draggable>
                                                                                
                                                                        ))}
                                                                    </tbody>

                                                                </table>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                        
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body">
                                            <div className="table-responsive mailbox-messages">
                                                    <table className="table table-hover table-striped">
                                                        <thead>
                                                            <td>Numero</td>
                                                            <td>SMS disponiveis</td>
                                                            <td>Zona</td>
                                                            <td>Acções</td>
                                                        </thead>
                                                        {(() => {
                                                            if(this.state.dataNumbers.length > 0){
                                                                return (
                                                                    <tbody>
                                                                        {/* {this.state.dataNumbers.map(numb =>
                                                                            <tr>
                                                                                <td className="mailbox-subject">{numb.numberphone}</td>
                                                                                <td className="mailbox-subject">{numb.smsdisponiveis}</td>
                                                                                <td className="mailbox-subject">{numb.zonasms}</td>
                                                                                <td className="mailbox-subject">
                                                                                    <button className="btn btn-warning">Editar</button>
                                                                                    <button className="btn btn-danger">Eliminar</button>
                                                                                </td>
                                                                            </tr>
                                                                        )} */}
                                                                    </tbody>
                                                                );

                                                            }else{
                                                                return (
                                                                    <p className="text-center">No data</p>
                                                                );
                                                            }
                                                        })()}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}


const mapState = (state) => {
    return {
        user: state.firebase.auth,
        admin: state.firebase.profile.admin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // saveRulesAdminFirebase : (list) => dispatch(saveRulesAdminFirebase(list))
        saveRuleAdminFirebase : (object) => dispatch(saveRuleAdminFirebase(object)),
        deleteAllRulesAdminFirebase : () => dispatch(deleteAllRulesAdminFirebase()),
        deleteAllAndSaveRulesAdminFirebase : rules => dispatch(deleteAllAndSaveRulesAdminFirebase(rules))
    }
}

const connected = connect(mapState, mapDispatchToProps)(AdminRules);
export { connected as AdminRules }