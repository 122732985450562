import React, { useEffect, useState  } from "react";

import axios from 'axios';
import ReactPaginate from "react-paginate";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { RadialSeparators } from '../../_components';
import { CSVLink, CSVDownload } from 'react-csv';
import { getFirestore } from "redux-firestore";

import { Text } from 'react-native';

const getResultEmails = (emails) => {
  let count = 0;
  emails.forEach((email, index) => {
      if(!email.status.includes("loader")){
          count += 1;
      }
  });

  return count;
}

const sleep = ms => {
  return new Promise(res => setTimeout(res, ms));
}

const TableMessages = ({messages, auth}) => {

  const [emailsList, setEmailsList] = useState(messages);
  const [selectList, setSelectList] = useState('all');

  const tablepage = emailsList;

  // const tablepage = emailsList.filter(tab => tab.status.includes(selectList) || selectList == 'all');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(7);
  // const [pageCount, setPageCount] = useState(Math.ceil(tablepage.length / totalPages));
  const pageCount = Math.ceil(tablepage.length / totalPages);
  const indexOfLast = currentPage * totalPages;
  const indexOfFirst = indexOfLast - totalPages;
  const [btnPPS, setBtnPPS] = useState('play');

  const [isRunning, setIsRunning] = useState(true);
  const [indexCountTable, setIndexCountTable] = useState(0);
  const visibilitypage = (currentPage * totalPages) > tablepage.length ? tablepage.length : (currentPage * totalPages);
  // const resultemails = getResultEmails(emailsList);
  const [csvReport, setCsvReport] = useState({data: [], headers: [], filename: ''});
  const [checkedItems, setCheckedItems] = useState([]);

  const url = 'https://falcon-g1ii.onrender.com/api/public/pingemails';


  useEffect(() => {
    let indexc = indexCountTable;
    const fetchData = async () => {
      let newArr = emailsList;
      try {
        const { data: i} = await axios.post(url, { email: newArr[indexc].email}, {headers: {'Access-Control-Allow-Origin': '*'}});
        newArr[indexc].status = i ? "<i class='fa fa-check' style='color:green; font-size:35px'></i>" : "<i class='fas fa-times' style='color:red; font-size:35px'></i>";
      }catch(e) {
        newArr[indexc].status = "<i class='fa fa-exclamation-triangle' style='color:orange; font-size:35px'></i>";
      }
      setEmailsList(newArr);

        setIndexCountTable(indexCountTable => indexCountTable+1);
    };


    if(isRunning && emailsList.length > indexc) {
          fetchData();
    }
    return undefined;
  }, [isRunning, indexCountTable]);

  const handleChangeCheckbox = e => {
    const value = e.target.id.replace("check-", "");
    const checked = e.target.checked;
    let items = checkedItems;
    const index = items.indexOf(value.toString());

    if(index > -1){
      items.splice(index, 1);
    }

    if(checked){
      items.push(value);
    }

    setCheckedItems(items);
  }

  const clickToogleCheckboxAll = e => {
    const { checked } = e.target;
    let checkboxs = document.querySelectorAll('checkboxid');
    let checks = [];

    for(let i = 0; i < checkboxs.length; i++){
      checkboxs[i].checked = checked;
      if(checked){
        checks.push(checkboxs[i].id.replace("check-", ""));
      }
    }

    setCheckedItems(checks);
  }

  const deleteMessages = e => {
    if(checkedItems.length == 0)
      return;

    const firestore = getFirestore();
    firestore.collection('users').doc(auth.uid).collection('messages').onSnapshot((docs) => {
      let items = [];

      docs.forEach(doc => {
        checkedItems.forEach(check => {
          if(check == doc.id){
            doc.ref.delete();
          }else{
            const { destino, message, send } = doc.data();
            items.push({
              id: doc.id,
              destino: destino,
              message: message,
              send: send
            });
          }
        });
      });
      setCheckedItems([]);
      setEmailsList(items);
    });

    let checkboxs = document.querySelectorAll('.checkboxid');
    for(let i = 0; i < checkboxs.length; i++){
      checkboxs[i].checked = false;
    }
  }

  const btnTableEmail = (what) => {
    if(what == "stop") {
      setBtnPPS('play');
      // onCheckStop();
      return;
    }

    setBtnPPS(what);
    setIsRunning(what == "play" ? true : false);

    let arr = emailsList;
    emailsList.forEach((email, index) => {
      if(email.status.includes('loader')){
        arr[index].status = "<div class='loader"+(what == "pause" ? " paused" : "")+"'></div>";
      }
    })

    setEmailsList(arr);
  }

  const handlePageClick = data => {
    console.log(data);
    setCurrentPage(parseInt(data.selected) + 1);
  }

  const downloadCSV = (e, done) => {

    const objReport = {
        filename: "test.csv",
        data: tablepage.map(tab => [tab.email])
    };

    setCsvReport(objReport);
}

  return (
    <>
    {/* <div className="mailbox-controls  justify-content-end">
      <div className="dropdown m-1">
          <span className="text-left" style={{fontSize:"30px", fontWeight:"bold"}}>List Results</span>
          <span className="float-right">
              <CSVLink {...csvReport} onClick={downloadCSV}>
                  <span style={{"margin-right": "5px"}} className="btn btn-default btn-sm btnfalc-csv"><i className="fa fa-download"></i></span>
              </CSVLink>
              <button className="btn dropdown-toggle btnfalc-dropdown" type="button"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Select
              </button>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => setSelectList('all')}>Todos</a>
                  <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => setSelectList('check')}>Válidos</a>
                  <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => setSelectList('times')}>Inválidos</a>
              </div>
          </span>
      </div>
    </div> */}

    <div className="p-0">
    <div className="mailbox-controls">
      {tablepage.length > 0
      ?
      <div className="btn-group">
          <div className="icheck-primary">
              <input type="checkbox" id="checkall" value="" onChange={clickToogleCheckboxAll} />
              <label for="checkall"></label>
          </div>
          <button type="button" className="btn btn-default btn-sm"  onClick={deleteMessages}><i className="far fa-trash-alt"></i></button>
      </div>
      :
      <div></div>
      }

  </div>
      <div className="table-responsive mailbox-messages">
        <div className="container">
          <table id="tablefalc" className="table table-hover">
            {btnPPS == 'pause' ?
              <div className="centerbutton">
                <button type="button" className="btn btn-falc-progress btn-circle btn-xxl">
                  <i className="fas fa-pause" />
                </button>
              </div>
              : <></>
            }
            <thead style={{backgroundColor:"#362544", color:"white", opacity: btnPPS == 'pause' ? 0.5 : 1}}>
              <tr>
                <th></th>
                <th style={{borderBottom: "none", borderTop: "none"}}>Desino</th>
                <th>Origem</th>
                <th>Message</th>
                <th>Enviada</th>
                <th className="float-right" style={{borderBottom:"none", borderTop: "none"}}>Estado</th>
              </tr>
            </thead>

            <tbody style={{opacity: btnPPS == 'pause' ? 0.5 : 1}}>
              {tablepage.slice(indexOfFirst, indexOfLast).map(tab =>
                <tr>
                  <td>
                    <div className="icheck-primary">
                      <input type="checkbox" value="" className="checkboxid" id={"check-"+tab.id} onChange={handleChangeCheckbox} />
                      <label for={"check-"+tab.id}></label>
                    </div>
                  </td>
                  <td className="mailbox-name"><a href="!#">{tab.destino}</a></td>
                  <td className="mailbox-name"><a href="!#">{tab.origem}</a></td>
                  <td className="mailbox-subject"><Text numberOfLine={1}>{tab.message.length < 35 ? tab.message : tab.message.substring(0,32) }</Text></td>
                  <td className="mailbox-date">{tab.send}</td>
                  <td className="mailbox-date"><i class={"fas fa-"+tab.stat}></i></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex-row align-items-center">
          <ReactPaginate
            previousLabel={currentPage == 1 ? null : '<<'}
            previousClassName={currentPage == 1 ? null : 'previous'}
            nextLabel={currentPage == pageCount ? null : '>>'}
            initialPage={0}
            nextClassName={currentPage == pageCount ? null : 'next'}
            breakLabel={null}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={null}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />

          <div className="float-right foot-table-falc">
            {visibilitypage}/{tablepage.length}
          </div>
        </div>

        {/* <Example label="">
          <CircularProgressbarWithChildren
            value={parseInt(Math.round(resultemails/emailsList.length*100))}
            text={`${parseInt(Math.round(resultemails/emailsList.length*100))}%`}
            strokeWidth={13}
            styles={buildStyles({
              strokeLinecap: "butt"
            })}
          >
            <RadialSeparators
              count={12}
              style={{
                  background: "#fff",
                  width: "10px",
                  // This needs to be equal to props.strokeWidth
                  height: `${20}%`
              }}
            />

            <div style={{fontSize:15, marginTop: 70}}>
              {resultemails}/{emailsList.length}
            </div>
          </CircularProgressbarWithChildren>
        </Example> */}

        {/* <div style={{textAlign:"center"}}>
          <button type="button" disabled={btnPPS == "play" ? "true" : ''} className="btn btn-falc-progress btn-circle btn-xl" onClick={() => btnTableEmail("play")}>
            <i className="fas fa-play"></i>
          </button>

          <button type="button" disabled={btnPPS == "pause" ? "true" : ''} className="btn btn-falc-progress btn-circle btn-xl" onClick={() => btnTableEmail("pause")}>
            <i className="fas fa-pause"></i>
          </button>

          <button type="button" disabled={btnPPS == "stop" ? "true" : ''} className="btn btn-falc-progress btn-circle btn-xl" onClick={() => btnTableEmail("stop")}>
            <i className="fas fa-stop"></i>
          </button>
        </div> */}
      </div>
    </div>
    </>
  );
};

function Example(props) {
  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{width:"20%", marginLeft:"auto", marginRight:"auto"}}>{props.children}</div>
      <div style={{width:"70%"}}>
        <h3 className="h5">{props.label}</h3>
        <p>{props.description}</p>
      </div>
    </div>
  );
}

export default TableMessages;
