import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../_actions';

class Header extends Component {
  render() {
    const { user, profile } = this.props;

    if(!user.uid) return <Redirect to='/login' />
    const photoUrl = user.photoURL ? user.photoURL : "/img/dist/avatar5.png";

    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="!#"><i className="fas fa-bars"></i></a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="!#">
              <i className="far fa-comments"></i>
              <span className="badge badge-danger navbar-badge">3</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <a href="!#" className="dropdown-item">
                <div className="media">
                  <img src="img/dist/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Brad Diesel
                      <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                    </h3>
                    <p className="text-sm">Call me whenever you can...</p>
                    <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item">
                <div className="media">
                  <img src="img/dist/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      John Pierce
                      <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
                    </h3>
                    <p className="text-sm">I got your message bro</p>
                    <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item">
                <div className="media">
                  <img src="img/dist/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Nora Silvester
                      <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
                    </h3>
                    <p className="text-sm">The subject goes here</p>
                    <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item dropdown-footer">See All Messages</a>
            </div>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="!#">
              <i className="far fa-bell"></i>
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">15 Notifications</span>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item">
                <i className="fas fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item">
                <i className="fas fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item">
                <i className="fas fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="!#" className="dropdown-item dropdown-footer">See All Notifications</a>
            </div>
          </li> */}



          <li className="nav-item ">

            <Link to="/payment" className="nav-link">
              <i className="far nav-icon fas fa-money-bill-wave fa-2x" style={{"vertical-align": "middle", "margin-right":"5px"}}></i>
              <span className="d-none d-md-inline">{profile.creditos} créditos</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="!#">
              <i className="fas fa-th-large"></i>
            </a>
          </li> */}


          <li className="nav-item dropdown user-menu">
            <a href="!#" className="nav-link dropdown-toggle" data-toggle="dropdown">
              <img src={photoUrl} className="user-image img-circle elevation-2" />
              <span className="d-none d-md-inline">{profile.name}</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <li className="user-header bg-primary">
                <img src={photoUrl} className="img-circle elevation-2" />
                <p>
                  {profile.name}
                  <small>{user.email}</small>
                  {/* <small>Member since Nov. 2012</small> */}
                </p>
              </li>
              {/* <li className="user-body">
                <div className="row">
                  <div className="col-4 text-center">
                    <a href="!#">Followers</a>
                  </div>
                  <div className="col-4 text-center">
                    <a href="!#">Sales</a>
                  </div>
                  <div className="col-4 text-center">
                    <a href="!#">Friends</a>
                  </div>
                </div>
              </li>  */}
              <li className="user-footer">
                <Link to="/profile" className="btn btn-default btn-flat">Profile</Link>
                <a onClick={this.props.logout} className="btn btn-default btn-flat float-right">Sign out</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

    );
  }
}

const mapState = (state) => {
  return {
    user: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

const connected = connect(mapState, mapDispatchToProps)(Header);
export { connected as Header };

// export default Header;
