import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
// import Register from './Register.js';

import { connect } from 'react-redux';

import { login, loginFacebook } from '../_actions';

import { Modal } from 'react-bootstrap';

class Login extends Component {
    
    // constructor(props) {
    //     super(props);
    // }

    state = {
        email: '',
        password: '',
        showModal: false,
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if(this.state.email && this.state.password){
            this.props.login(this.state);
        }
    }

    handleLoginFacebook = (event) => {
        event.preventDefault();

        this.props.loginFacebook();
    }

    handleCloseModal = e => {
        this.setState({
            showModal: false
        });
    }

    showTermos = e => {
        e.preventDefault();
        this.setState({
            showModal: true
        });
    }


    render() {
        // const { loggingIn } = this.props;
        const { authError, auth } = this.props;
        const { email, password, submitted } = this.state;

        if(auth.uid) return <Redirect to='/' />

        return ( 
            <div className="container falc-register" style={{"font-size":"75%"}}>
                {alert.message &&
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                }

                <div className="card m-5 p-5 cardfalc">
                    <div className="login-logo">
                    <img src="img/dist/logo_mini.png" className="brand-image img-circle elevation-3" style={{opacity: .8}} />
                    </div>
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Welcome Back, Please login to your account</p>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="form-group input-material">
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        name="email"
                                        value={email} 
                                        onChange={this.handleChange}
                                        id="email-field"
                                    />
                                    <label form="email-field">Email</label>
                                </div>
                                <div className="form-group input-material">
                                    <input  
                                        type="password" 
                                        name="password"
                                        value={password} 
                                        className="form-control" 
                                        id="password-field"
                                        onChange={this.handleChange}
                                    />
                                    <label form="password-field">Password</label>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <p className="mb-1 float-right"><Link to="/forgot">Forgot Password</Link></p>
                                    </div>
                                </div>



                                <div>
                                    <div className="row" style={{"margin-top":"55px"}}>
                                        <div className="col text-center m-1">
                                            <button type="submit" className="btn btn-block btn-primary">Login</button>
                                        </div>

                                        <div className="col text-center m-1">
                                            <Link to="/register" className="btn btn-block btn-default">Sign up</Link>
                                        </div>
                                    </div>

                                    <div className="row" style={{"margin-top":"10px"}}>
                                        <div className="col text-center m-1">
                                            <button style={{"border-radius":"15px 0 15px 0"}} className="btn btn-block btn-primary" onClick={this.handleLoginFacebook}>Log in with <i className="fab fa-facebook-f mr-2"></i></button>
                                        </div>
                                        <div className=" col text-center m-1">
                                            <button style={{"border-radius":"15px 0 15px 0"}} className="btn btn-block btn-danger" onClick={this.handleLoginFacebook}>Log in with <i className="fab fa-google mr-2"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Termos</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Texto termos</Modal.Body>
                                <Modal.Footer>
                                        <button variant="secondary" onClick={this.handleCloseModal}>Close</button>
                                </Modal.Footer>
                            </Modal>
                            <div className="row" style={{"margin-top":"95px"}}>
                                <a href="!#" className="text-center col" onClick={this.showTermos}>Term of use. Privacy policy</a>
                            </div>
                                        <div className="center red-text">
                                            { authError ? <p>{authError}</p> : null }
                                        </div>
                         
                        </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        authError: state.authentication.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        login: (creds) => dispatch(login(creds)),
        loginFacebook: () => dispatch(loginFacebook())
    }
}

export default connect(mapState, mapDispatchToProps)(Login);
