export const saveFeedback = (uid, opinion, category, message) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        console.log(opinion+" "+category+" "+message);
        firestore.collection('users').doc(uid).collection('feedback').add({
            opinion: opinion,
            category: category,
            feedback: message
        });
    }
}