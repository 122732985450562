import { get } from "jquery";
import { getFirebase } from "react-redux-firebase";
import moment from "moment";

export const savePriceAdminFirebase = (code, price, indicador) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('prices')
        .collection('countries').doc(code).set({
            indicador: indicador,
            price: price
        });
    }
}

export const savePriceUserAdminFirebase = (price) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('prices')
        .collection('user').doc('values').set({
            price:price
        });
    }
}

export const savePriceEmailAdminFirebase = (price) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('prices')
        .collection('emails').doc('values').set({
            price:price
        });
    }
}

export const deletePriceAdminFirebase = (code) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('prices')
        .collection('countries').doc(code).delete();
    }
}

export const deleteAllCreditAdminFirebase = () => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('credits')
        .collection('values').get()
        .then((res) => {
            res.forEach((e) => {
                e.ref.delete();
            })
        });
    }
}

export const saveCreditAdminFirebase = (credit, price) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('credits').collection('values').add({
            credit: credit,
            price: price
        });
    }
}

export const updateAllSMSRulesAdminFirebase = () => {
    console.log('updateAllSMSRulesAdminFirebase');
    return (dispatch, getState, { getFirestore} ) => {
        console.log('return updateAllSMSRulesAdminFirebase');
        const firestore = getFirestore();
        const day_now = moment().format("DD");
        const upd_now = moment().format("DD-MM-YYYY");

        firestore.collection('admin').doc('rules').collection('values')
        .get().then(res => {
            res.forEach(e => {
                let data = e.data();
                let diarenovavel = e.diarenovavel;
                let smsrenovaveis = e.smsrenovaveis;
                let diasrenovaveis = e.diasrenovaveis;
                if(diasrenovaveis == (day_now - diarenovavel)){
                   
                    console.log("upd renovaveis");
                    firestore.collection('admin').doc('rules')
                    .collection('values').doc(e.id).set({
                        smsdisponiveis: e.smsrenovaveis,
                        date_upd: upd_now
                    });
                    
                }
                console.log(e.data());
            });
        });
    }
}

export const deleteAllAndSaveRulesAdminFirebase = (rules) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('rules').collection('values')
        .get().then((res) => {
            res.forEach(e => {
                e.ref.delete();
            });

            rules.forEach(rule => {
                firestore.collection('admin').doc('rules').collection('values')
                .add({
                    order: rule.id,
                    areaCode: rule.areaCode,
                    numberphone: rule.numberphone,
                    smsdisponiveis: rule.smsdisponiveis,
                    zonasms: rule.zonasms,
                    smsrenovaveis: rule.smsrenovaveis,
                    diasrenovaveis: rule.diasrenovaveis,
                    diarenovavel: rule.diarenovavel,
                    date_new: rule.date_new,
                    date_upd: rule.date_upd
                });
            });
        })
    }
}

export const deleteAllRulesAdminFirebase = () => {
    return (dispatch, getState, { getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('rules')
        .collection('values').get()
        .then((res) => {
            res.forEach((e) => {
                e.ref.delete();
            });
        });
    }
}

export const saveRuleAdminFirebase = (obj) => {
    return (dispatch, getState, {getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('rules').collection('values').add({
            order: obj.id,
            areaCode: obj.areaCode,
            numberphone: obj.numberphone,
            smsdisponiveis: obj.smsdisponiveis,
            zonasms: obj.zonasms
        }).then(() => {
            console.log("added")
        }).catch(err => {
            console.log(err);
        });
    }
}

// export const saveRulesAdminFirebase = (list) => {
//     return (dispatch, getState, {getFirestore}) => {
//         const firestore = getFirestore();

//         firestore.collection('admin').doc('rules')
//         .collection('values').get()
//         .then((res) => {
//             res.forEach((e) => {
//                 e.ref.delete();
//             });
//         });

//         list.forEach((l) => {
//             firestore.collection('admin').doc('rules').collection('values').add({
//                 order: l.id,
//                 areaCode: l.areaCode,
//                 numberphone: l.numberphone,
//                 smsdisponiveis: l.smsdisponiveis,
//                 zonasms: l.zonasms
//             });
//         });
//     }

// }