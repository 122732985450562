import React, {Component, useState} from 'react';
import validator from "validator";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { sendMessageFirebase, calculateCredits } from '../_actions';
import { Header, SideBar } from './';
import { csvTojson } from '../_helpers';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import { getFirestore } from 'redux-firestore';
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import ReactCustomFlagSelect from 'react-custom-flag-select';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";

import DateFnsUtils from '@date-io/date-fns';
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';

import "./stylemessage.css";
import { readRemoteFile } from 'react-papaparse';
import datacsv from '../_helpers/numbers-countries.csv';

import Modal from 'react-bootstrap/Modal';

import { count } from "sms-length";
import { getFirebase } from 'react-redux-firebase';
import TableMessages from './utils/TableMessages';

const find = (arr, obj) => {
    if(arr.length == 0) return;
    const res = [];
    arr.forEach(o => {
      let match = false;
      Object.keys(obj).forEach(i => {
        if (obj[i] === o[i]) {
          match = true;
        }
      });
      if (match) {
        res.push(o);
      }
    });
    return res;
  };



class Message extends Component {
    constructor(props){
        super(props);

        this.csvUpload = React.createRef();

        this.state = {
            destinos: [],
            details: {
                chars: 0,
                parts: 0,
                total_credits: 0
            },
            pricesCountry: [],
            origem: '',
            message: '',
            submitted: false,
            table: [],
            novo_numero: '',
            checkedItems: [],
            areaCode: "0",
            phone: '',
            validate: false,
            hasPhoneError: true,
            FLAG_SELECTOR_OPTION_LIST: [],
            modal: {
                show: false,
                title: '',
                mensagem: '',
                success: ''
            },
            showDateTimePicker: false,

            selectedDate: new Date(),

            priceUser : 0,
            rulesUser: []
        };

        this.getRulesUser();

    }

    calculatePriceCredit = (message, destinos) => {
        const { pricesCountry, FLAG_SELECTOR_OPTION_LIST, priceUser, rulesUser } = this.state;
        let arr = [];
        let total = 0;
        let count_sms = count(message);
        let rules = [];

        rulesUser.forEach(rule => {
            rules.push(rule);
        });

        if(message.length == 0 || destinos.length == 0){
            return;
        }

        for(var n of destinos){
            for(var rule of rules){
                if(rule.numberphone == 'falcon'){
                    console.log("numbfalcon");
                    for(var f of FLAG_SELECTOR_OPTION_LIST){
                        if(validator.isMobilePhone(n, f.locale)){
                            arr.push(f.id);
                            break;
                        }
                    }

                    break;
                }else{
                    let smsdisp = rule.smsdisponiveis;
                    console.log("normal: "+smsdisp);
                    if(smsdisp >= count_sms.messages){
                        smsdisp -= count_sms.messages;
                        total += parseFloat(priceUser)*parseInt(count_sms.messages);
                        break;
                    }
                }
            }
        }

        arr.forEach(a => {
            pricesCountry.forEach(p => {
                if(p.number == a){
                    total += parseFloat(p.price);
                }
            });
        });

        this.setState({
            details: {
                total_credits: total*destinos.length,
                chars: count_sms.length,
                parts: count_sms.messages
            }
        });
    }

    handleDateChange = date => {
        this.setState({
            selectedDate: date
        });

        console.log(this.state.selectedDate);
    }

    componentDidMount(){
        if(!this.props.auth.uid) return false;

        this.getCodesCountries();
        this.getPriceUser();
        this.getMessages();
    }

    getRulesUser = () => {
        const firestore = getFirestore();

        firestore.collection('users').doc(this.props.auth.uid).collection('rules').onSnapshot(docs => {
            let arr = [];
            docs.forEach(doc => {
                const { order, numberphone, smsdisponiveis } = doc.data();
                arr.push({
                    order: order,
                    smsdisponiveis: smsdisponiveis,
                    numberphone: numberphone
                });


            });

            arr.sort((a,b) => parseInt(a.order) - parseInt(b.order));

            this.setState({
                rulesUser: arr
            })
        })
    }

    getPriceUser = () => {
        const firestore = getFirestore();

        firestore.collection('admin').doc('prices').collection('user').doc('values')
            .get().then(doc => {
                if(doc.exists){
                    const { price } = doc.data();

                    this.setState({
                        priceUser: price
                    });
                }
            })
    }

    getCodesCountries = () => {
        const firestore = getFirestore();
        this.ref = firestore.collection('admin').doc('prices').collection('countries').onSnapshot((docs) => {
            let data = [];
            docs.forEach((doc) => {
                const codef = doc.id;
                const { indicador, price} = doc.data();
                data.push({
                    code:codef,
                    number: indicador,
                    price: price
                });
            });

            this.setState({
                pricesCountry: data
            });

            this.saveCountries(data);
        });
    }

    saveCountries = (countries) => {
        readRemoteFile(datacsv, {
            complete: (results) => {
                let arr = [];

                results.data.forEach((d) => {
                    countries.forEach((c) => {
                        if(d[3].toLowerCase() == c.code.toLowerCase()){
                            arr.push({
                                id: d[5].toString(),
                                name: d[0],
                                displayText: d[3].toString()+"("+d[5].toString()+")",
                                locale: d[6],
                                flag: 'http://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/'+d[3].toLowerCase()+'.svg'
                            });
                        }
                    });
                });

                this.setState({
                    FLAG_SELECTOR_OPTION_LIST: arr,
                    areaCode: arr.length > 0 ? arr[0].id : -1
                });
            }

        })
    }

    getMessages = () => {
        const firestore = getFirestore();
        this.ref = firestore.collection('users').doc(this.props.auth.uid).collection('messages').onSnapshot((docs) => {
            let data = [];
            docs.forEach((doc) => {
                const { destino, message, origem, send, falcon, check } = doc.data();
                let stat = "";

                switch(check){
                    case 0:
                        stat = "clock";
                        break;
                    case 1:
                        stat = "check";
                        break;
                    case 2:
                        stat = "check-double";
                        break;
                    default:
                        break;
                }

                data.push({
                    id: doc.id,
                    destino: destino,
                    message: message,
                    origem: falcon ? "FALCON" : origem,
                    send: send,
                    stat: stat
                });
            });

            this.setState({
                table: data
            });

        });
    }

    deleteMessages = (e) => {
        const { checkedItems } = this.state;


        if(checkedItems.length == 0)
            return;

        const firestore = getFirestore();
        firestore.collection('users').doc(this.props.auth.uid).collection('messages').onSnapshot((docs) => {
            let items = [];

            docs.forEach((doc) => {
                checkedItems.forEach((check) => {
                    if(check == doc.id){
                        doc.ref.delete();
                    }else{
                        const { destino, message, send } = doc.data();
                        items.push({
                            id: doc.id,
                            destino: destino,
                            message: message,
                            send: send
                        });
                    }
                });
            });

            this.setState({
                checkedItems: [],
                table: items
            });
        });

        let checkboxs = document.querySelectorAll('.checkboxid');
        for(let i = 0; i < checkboxs.length; i++){
            checkboxs[i].checked = false;
        }

    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

        if(name == 'message'){
            // this.countMessage(value);
            this.calculatePriceCredit(value, this.state.destinos);
        }
    }

    // countMessage = (text) => {
    //     let count1 = count(text);
    //     console.log(count1);
    //     this.setState({
    //         nr_sms: count1.length,
    //         parts: count1.messages
    //     })
    // }

    handleChangeSelect = (e) => {
        e.preventDefault();
        let names = Array.from(e.target.selectedOptions, option => option.value)
        this.setState({
            destinos: names
        });

        this.calculatePriceCredit(this.state.message, names);
    }

    toggleValiditing = (validate) => {
        this.setState({ validate });
    }

    sendNumberToSelect = (e) => {
        this.toggleValiditing(true);

        const { novo_numero, destinos, areaCode, hasPhoneError, phone } = this.state;
        let codErr = false;

        this.state.FLAG_SELECTOR_OPTION_LIST.forEach((f) => {
            if(parseInt(f.id) == parseInt(areaCode)){
                let numb = "+"+areaCode+""+phone;

                if(!validator.isMobilePhone(numb, f.locale)){
                    codErr = true;
                }

                return;
            }
        });

        if(!hasPhoneError && !codErr){
            destinos.push("+"+areaCode+""+phone);

            this.setState({
                destinos: destinos,
                phone: ''
            });
        }

    }

    handlePhoneChange = (phone) => {
        this.setState({
            phone : phone
        });
    }

    handleSend = (event) => {
        event.preventDefault();
        this.setState({
            submitted: true
        });

        const { destinos, origem, message, selectedDate, showDateTimePicker } = this.state;
        const uid = this.props.auth.uid;
        const creditos = this.props.profile.creditos;

        console.log(selectedDate.toLocaleDateString()+" "+selectedDate.toLocaleTimeString(
            navigator.language, {hour: '2-digit', minute: '2-digit'}
        ));

        let datetime = {
            showDateTime: showDateTimePicker,
            date: showDateTimePicker ? selectedDate.toLocaleDateString() : null,
            time: showDateTimePicker ? selectedDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute: '2-digit'}) : null
        };

        if(!destinos.length > 0)
            return;

        let arr = [];

        destinos.forEach((n) => {
            this.state.FLAG_SELECTOR_OPTION_LIST.forEach((f) => {
                if(validator.isMobilePhone(n, f.locale)){
                    arr.push(f.id);
                    return;
                }
            })
        });

        // this.calculateCredits(arr);
        this.props.sendMessageFirebase(destinos, message, uid, datetime);

        getFirestore().collection('users').doc(this.props.auth.uid)
            .update({
                'creditos': parseFloat(this.props.profile.creditos) - parseFloat(this.state.details.total_credits)
            });


        this.getMessages();

        this.setState({
            destinos: [],
            details: {
                chars: 0,
                parts: 0,
                total_credits: 0
            },
            origem: '',
            message: "",
            novo_numero: '',
            phone: '',
            validate: false,
            hasPhoneError: true,
            modal: {
                show: false,
                title: '',
                mensagem: '',
                success: ''
            },
            showDateTimePicker: false,

            selectedDate: new Date(),
        });

        this.refs.message.value = "";
    }

    calculateCredits = (indicators, destinos, message) => {
        const creditos = this.props.profile.creditos;
        const uid = this.props.auth.uid;
        const firestore = getFirestore();
        let prices = this.getPricesFirestore();
        let total = 0;
        prices.then((res) => {

            indicators.forEach((d) => {
                res.forEach((p) => {
                    if(p.indicator === d){
                        total += parseFloat(p.price);
                    }
                })

            })

            console.log(total);

            if((parseFloat(total) > parseFloat(creditos))){
                this.messageResponseSend(total, false);
                return;
            }

            this.props.sendMessageFirebase(destinos, message, uid);

            firestore.collection('users').doc(uid).update({
                'creditos': parseFloat(creditos) - parseFloat(total)
            });

            this.messageResponseSend(total, true);

        });

    }

    messageResponseSend = (total_preco, success) => {
        const creditos = this.props.profile.creditos;

        let msg = success ?
                "Mensagem enviada com sucesso. Gastou "+total_preco+" creditos. e tem agora "+(parseFloat(creditos)-parseFloat(total_preco))+" creditos."
                :
                "Nao foi enviada a mensagem porque nao possui creditos suficientes. O custo é de "+total_preco+" creditos";

        this.setState({
            modal: {
                show: true,
                title: success ? 'Mensagem enviada com sucesso' : 'Erro ao enviar a mensagem',
                mensagem: msg,
                success: success ? 'green' : 'red'
            }
        });
    }

    handleCloseModal = () => {
        this.setState({
            modal: {
                show: false,
                title: '',
                mensagem: '',
                success: ''
            }
        });
    }

    getPricesFirestore = () => {
        const firestore = getFirestore();
        return firestore.collection('admin').doc('prices')
        .collection('countries').get()
        .then((res) => {
            let prices = [];
            res.forEach((e) => {
                let dat = e.data();
                prices.push({
                    indicator: dat.indicador,
                    price: dat.price
                });
            });

            return prices;
        })
    }

    onSuccessMessage = (credito_preco, credito_total, success) => {
        console.log("preco: "+credito_preco+", total: "+credito_total+", success: "+success);
    }

    handleClickFile = (e) => {
        console.log(this.state.areaCode);
       this.csvUpload.current.click();
    }

    handleChangeCSV = (event) => {
        const { areaCode, destinos } = this.state;
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = (event) => {
            var json =  this.props.csvTojson(event.target.result, ';');
            let arr = destinos;
            json.forEach((n) => {
                let number = "+"+areaCode+n;
                this.state.FLAG_SELECTOR_OPTION_LIST.forEach((f) => {
                    if(validator.isMobilePhone(number,f.locale)){
                        arr.push(number);
                        return;
                    }
                })
            })
            this.setState({
                destinos: arr,
            });

        };
    }

    clickToogleCheckboxAll = (e) => {
        const { checked } = e.target;
        let checkboxs = document.querySelectorAll('.checkboxid');
        let checks = [];

        for(let i = 0; i < checkboxs.length; i++){
            checkboxs[i].checked = checked;
            if(checked){
                checks.push(checkboxs[i].id.replace("check-", ""));
            }
        }

        this.setState({
            checkedItems: checks
        });
    }

    handleChangeCheckbox = (e) => {
        const value = e.target.id.replace("check-", "");
        const checked = e.target.checked;
        var items = this.state.checkedItems;
        const index = items.indexOf(value.toString());

        if(index > -1){
            items.splice(index, 1);
        }

        if(checked){
            items.push(value);
        }


        this.setState({
            checkedItems: items
        });
    }

    activeAgendarSMS = (e) => {
        this.setState({
            showDateTimePicker: e.target.checked
        })
    }

    render() {
        const { destinos, message, novo_numero, areaCode, phone, validate, FLAG_SELECTOR_OPTION_LIST, modal } = this.state;
        const currentItem = FLAG_SELECTOR_OPTION_LIST.length > 0 ? find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0] : null;

        this.state.origem = this.props.auth.phoneNumber;

        if(!this.props.auth.uid) return <Redirect to='/login' />

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Message</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Home</a></li>
                                            <li className="breadcrumb-item active">Message</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-5">
                                        {(this.state.FLAG_SELECTOR_OPTION_LIST.length > 0 &&
                                            this.props.profile.block == false)
                                            ?

                                            <form name="form" onSubmit={this.handleSend}>
                                                <div className="card">

                                                    <div className="card-body">
                                                        <div className="input-group">
                                                            <div className="form-group">

                                                                {
                                                                currentItem == null ?

                                                                 <input
                                                                    className="form-control"
                                                                    name="novo_numero"
                                                                    value={novo_numero}
                                                                    placeholder="Novo numero: "
                                                                    onChange={this.handleChange}
                                                                />
                                                                :

                                                                <div style={{ position: "relative", paddingRight:"25px" }}>
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        left: "0", height: "45px"
                                                                    }}>
                                                                        <ReactCustomFlagSelect
                                                                            tabIndex={"1"} //Optional.[String or Number].Default: -1.
                                                                            id={"areaCode"} //Optional.[String].Default: "". Input ID.
                                                                            name={"areaCode"} //Optional.[String].Default: "". Input name.
                                                                            value={currentItem.id} //Optional.[String].Default: "".
                                                                            disabled={false} //Optional.[Bool].Default: false.
                                                                            showSearch={true} ////Optional.[Bool].Default: false.
                                                                            showArrow={true} //Optional.[Bool].Default: true.
                                                                            animate={true} //Optional.[Bool].Default: false.
                                                                            optionList={FLAG_SELECTOR_OPTION_LIST} //Required.[Array of Object(s)].Default: [].
                                                                            // selectOptionListItemHtml={<div>us</div>} //Optional.[Html].Default: none. The custom select options item html that will display in dropdown list. Use it if you think the default html is ugly.
                                                                            // selectHtml={<div>us</div>} //Optional.[Html].Default: none. The custom html that will display when user choose. Use it if you think the default html is ugly.
                                                                            classNameWrapper="" //Optional.[String].Default: "".
                                                                            classNameContainer="" //Optional.[String].Default: "".
                                                                            classNameOptionListContainer="" //Optional.[String].Default: "".
                                                                            classNameOptionListItem="" //Optional.[String].Default: "".
                                                                            classNameDropdownIconOptionListItem={""} //Optional.[String].Default: "".
                                                                            customStyleWrapper={{ height: "100%" }} //Optional.[Object].Default: {}.
                                                                            customStyleContainer={{ border: "none", fontSize: "12px" }} //Optional.[Object].Default: {}.
                                                                            customStyleSelect={{
                                                                                width: "80px",
                                                                                // paddingTop: "0",
                                                                                // paddingBottom: "0",
                                                                                // height: "45px",
                                                                                // paddingLeft: "90px",
                                                                                // paddingRight: "20px"
                                                                            }} //Optional.[Object].Default: {}.
                                                                            customStyleOptionListItem={{}} //Optional.[Object].Default: {}.
                                                                            customStyleOptionListContainer={{
                                                                                maxHeight: "100px",
                                                                                overflow: "auto",
                                                                                width: "120px"
                                                                            }} //Optional.[Object].Default: {}.
                                                                            onChange={areaCode => {
                                                                                this.setState({ areaCode: areaCode }, () => {
                                                                                this.handlePhoneChange(phone);
                                                                                });
                                                                            }}
                                                                            // onBlur={() => {}} //Optional.[Func].Default: none.
                                                                            // onFocus={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                                                                            // onClick={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                                                                        />
                                                                    </div>


                                                                    <Textbox
                                                                        attributesInput={{
                                                                        id: "phone",
                                                                        placeholder: "MOBILE NUMBER",
                                                                        type: "text"
                                                                        }}
                                                                        customStyleWrapper={{ height: "100%" }}
                                                                        customStyleContainer={{ height: "100%" }}
                                                                        customStyleInput={{
                                                                        paddingTop: "0",
                                                                        paddingBottom: "0",
                                                                        height: "45px",
                                                                        paddingLeft: "90px",
                                                                        paddingRight: "20px",
                                                                        fontWeight: "bold",
                                                                        backgroundColor:  "#362544",
                                                                        borderColor:"#362544",
                                                                        color: "white"
                                                                        }}
                                                                        value={phone}
                                                                        validate={validate}
                                                                        validationCallback={res =>
                                                                        this.setState({
                                                                            hasPhoneError: res,
                                                                            validate: false
                                                                        })
                                                                        }
                                                                        type="text"
                                                                        placeholder="Numero telemovel"
                                                                        onChange={res => {
                                                                        this.handlePhoneChange(res);
                                                                        }}
                                                                        onBlur={() => {}}
                                                                        validationOption={{
                                                                        check: true,
                                                                        required: true,
                                                                        customFunc: phone => {
                                                                            if (validator.isMobilePhone(`${phone}`, currentItem.locale)) {
                                                                                return true;
                                                                            } else {
                                                                                return `Invalid phone format for ${currentItem.locale}`;
                                                                            }
                                                                        }
                                                                        }}
                                                                    />
                                                                </div>
                                                                }
                                                            </div>

                                                            <div className="form-group" style={{paddingRight:"25px"}}>
                                                                <button type="button" className="btn btn-primary btnfalcradiu" onClick={this.sendNumberToSelect}>Guardar</button>
                                                            </div>

                                                            <div className="form-group">
                                                                <button type="button" className='btn btn-default btnfalcradiu' onClick={this.handleClickFile}>Browse CSV</button>
                                                                <input
                                                                    className="form-control"
                                                                    name="csv"
                                                                    type="file"
                                                                    ref={this.csvUpload}
                                                                    style={{display: 'none'}}
                                                                    onChange={this.handleChangeCSV}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group select2-primary">


                                                            <Select2
                                                                classname="select2"
                                                                multiple
                                                                style={{width: '100%'}}
                                                                data={destinos}
                                                                value={destinos}
                                                                onChange={this.handleChangeSelect}
                                                                options=
                                                                {{
                                                                    tags: false,
                                                                    allowClear: false,
                                                                    placeholder: 'Numeros de destino',
                                                                    minimumResultsForSearch: -1,
                                                                }}
                                                            />

                                                            <span style={{background:"#362544", color: "white", fontWeight: "bold", padding:"0 25px", marginLeft:"90%"}}>{this.state.destinos.length}</span>

                                                        </div>

                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                        <div className="form-group">
                                                            <textarea
                                                                className="form-control"
                                                                ref="message"
                                                                rows="3"
                                                                placeholder="Message: "
                                                                name="message"
                                                                onChange={this.handleChange}
                                                            >{message}</textarea>
                                                            {/* <input
                                                                className="form-control"
                                                                name="message"
                                                                value={message}
                                                                placeholder="Message: "
                                                                onChange={this.handleChange}
                                                            /> */}
                                                        </div>

                                                        <div className="form-group icheck-primary">
                                                            <input type="checkbox" value="" id="checkboxagenda" className="form-control" onChange={this.activeAgendarSMS} />
                                                            <label for="checkboxagenda">Agendar</label>
                                                        </div>

                                                        { this.state.showDateTimePicker ?
                                                            <div className="form-group" id="inputagenda">
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <DateTimePicker ampm={false} minDate={new Date()} format="yyyy/MM/dd hh:mm" disablePast label="Data e Hora" value={this.state.selectedDate} onChange={this.handleDateChange} />
                                                                </MuiPickersUtilsProvider>

                                                                {/* https://stackoverflow.com/questions/57618675/how-to-make-disabled-date-and-time-in-react-datepicker */}
                                                            </div>
                                                            : null

                                                        }

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="bg-iphone">
                                                                    <div class="iphone-senderid wordwrap i-senderid">FALCON</div>
                                                                    <div className="iphone-grey-cloud">
                                                                        <div className="iphone-cloud-text wordwrap phone-msg-area">
                                                                            {message}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div class="pricing-table">
                                                                    <div class="pricing-item">
                                                                        <div class="pricing-title">DETAILS</div>
                                                                        <div class="pricing-value"><span class="smallText">{this.state.details.total_credits} cred.</span></div>
                                                                        <ul class="pricing-features">
                                                                            <li><span class="keywords">{destinos.length}</span> Envios</li>
                                                                            <li>{destinos.length*this.state.details.parts} <span class="keywords">sms</span></li>
                                                                            <li><span class="keywords">{this.state.details.chars} </span> carateres</li>
                                                                            <li><span class="keywords">{this.state.details.chars*destinos.length}</span> total</li>
                                                                        </ul>
                                                                        {this.props.profile.creditos > this.state.details.total_credits ?
                                                                            <button type="submit" class="btn btn-primary btnfalcradiu" style={{marginLeft: "20%", marginTop: "-7%", position: "absolute", zIndex:"2",  fontSize: "22px"}}>SEND <i className="fas fa-paper-plane"></i></button>
                                                                            :
                                                                            <>
                                                                                <p class="smallText" style={{color: 'red'}}>Não tem créditos suficientes</p>
                                                                                <button type="button" class="btn btn-outline-primary">Buy credits</button>
                                                                            </>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        </div>
                                                    </div>
                                        </form>

                                                  :
                                                 <div className="text-center">
                                                   <img width="572" src="https://www.seekpng.com/png/full/990-9902766_keep-calm-its-not-over-yet-poster-not.png" className="img-circle elevation-2" />
                                                </div>

                                             }


                                    </div>

                                    <div className="col-md-7">
                                        {this.state.table.length > 0 ?
                                            <TableMessages messages={this.state.table} auth={this.props.auth} />
                                            : <></>
                                        }

                                    </div>

                                    <Modal variant={modal.success} show={modal.show} onHide={this.handleCloseModal}>
                                        <Modal.Header closeButton><Modal.Title>{modal.title}</Modal.Title></Modal.Header>
                                        <Modal.Body>{modal.mensagem}</Modal.Body>
                                        <Modal.Footer><button variant="secondary" onClick={this.handleCloseModal}>Close</button></Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        datas: state.datas,
        profile: state.firebase.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // sendMessageFirebase : (destino, origem, message, uid) => dispatch(sendMessageFirebase(destino, origem, message, uid)),
        sendMessageFirebase : (destino, message, uid, dateTime) => dispatch(sendMessageFirebase(destino, message, uid, dateTime)),
        csvTojson : (csv, split) => csvTojson(csv, split),
        calculateCredits : (destinos, creditos, uid) => dispatch(calculateCredits(destinos, creditos, uid))
    }
}

const connected = connect(mapStateToProps, mapDispatchToProps)(Message);
export { connected as Message };
