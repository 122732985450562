import React, { Component, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, SideBar } from './';
import { saveWebHeatmap, removeHeatmap } from '../_actions';
import axios from 'axios';
import h337 from 'heatmap.js';
import { FormWithConstraints, FieldFeedback,  FieldFeedbacks, Input } from 'react-form-with-constraints';
import { getFirestore } from 'redux-firestore';
import html2canvas from 'html2canvas';

class Heatmap extends Component {
    constructor(props){
        super(props);
        this.state = {
          imageSrc: null,
          events: null,
          showClicks: false,
          showMoves: false,
          heatmapInstance: null,
          showHeatmap: false,
          newHeatmap: false,
          listHeatmaps: [],
          msgError: null
        };
    }


    componentDidMount = () => {
        // this.getPage();
        // this.getEvents();
        this.getListHeatmaps();
        // const heatmapInstance = h337.create({
        //     container: document.querySelector('#heatmap'),
        //     radius: 90
        // });
        // this.setState({ heatmapInstance });
    }

    getListHeatmaps = () => {
      const firestore = getFirestore();
      firestore.collection('heatmaps').doc(this.props.user.uid).collection('heatmap').onSnapshot((docs) => {
        let data = [];
        docs.forEach((doc) => {
          const { name, website } = doc.data();
          data.push({
            id: doc.id,
            name: name,
            website: website
          });
        });
        this.setState({
          listHeatmaps: data
        });

        if(this.state.listHeatmaps.length == 0){
          this.setState({
            showHeatmap: false,
            newHeatmap: true,
            imageSrc: null
          });
        }
      });
      console.log(this.state.listHeatmaps.length);
    }

    handleSubmitNewHeatmap = e => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const { saveWebHeatmap, user } = this.props;

      saveWebHeatmap(user.uid, formData.get('name'), formData.get('website'))
        .then(res => {
          if(res.success){
            this.getListHeatmaps();
            this.setState({
              newHeatmap: false
            });
          }else{
            this.setState({
              msgError: res.message
            });

            setTimeout(() => {
              this.setState({
                msgError: ''
              });
            }, 3000);
          }


        });
    };

    getPage = async (website) => {
      // const url = 'http://localhost:8082/api/public/heatmap';
      const url = 'https://site.falc-on.com/screenshot';
      try {
        const {data: response} = await axios.post(url, { website, 'uid':this.props.user.uid }, { responseType: 'arraybuffer' });
        const imageData = Buffer.from(response).toString('base64');
        const imageSrc = `data:image/png;base64,${Buffer.from(response).toString('base64')}`;
        this.setState({ imageSrc });
        this.setState({showHeatmap: true});
        this.getEvents(website);


      } catch(e){
        this.setState({
          showHeatmap: false,
          imageSrc: null
        });
        if(e.response){
          const arrayBuffer = e.response.data;
          const textDecoder = new TextDecoder();
          const errorMessage = textDecoder.decode(new Uint8Array(arrayBuffer));
          alert(errorMessage);
        }else{
          console.error(e.message);
        }
      }

    }

    removePage = web => {
      this.props.removeHeatmap(this.props.user.uid, web);
    };

    getEvents = async (website) => {
      const url = 'https://falcon-g1ii.onrender.com/api/public/events';
      console.log(website);
      try {
        const {data: events} = await axios.post(url, { website, 'uid':this.props.user.uid });
        console.log("events", events);
        this.setState({ events });
        this.renderHeatmap();
      } catch(e){
        console.error(e.message);
      }
    }

    renderHeatmap = () => {
      const heatmapData = [];
      const elements = document.getElementsByClassName("heatmap-canvas");
      while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
      }
      // $(".heatmap-canvas").remove();
      this.state.events.forEach(event => {
          if ((event.type === 'click' && this.state.showClicks) ||
          (event.type === 'move' && this.state.showMoves)) {
              heatmapData.push({ x: parseInt(event.x), y: parseInt(event.y), value: 1 });
          }
      });
      const heatmapInstance = h337.create({
          container: document.querySelector('#heatmap'),
          radius: 90
      });
      heatmapInstance.setData({ data: heatmapData });
      console.log(heatmapInstance.getData());
    }

    handleBackHeatmap = () => {
      this.setState({
        showHeatmap: false,
        newHeatmap: false
      });
    }


   handleButtonClick = (eventType) => {
     console.log("eventType", eventType);
       if (eventType === 'click') {
           this.setState({ showClicks: !this.state.showClicks }, this.renderHeatmap);
       } else if (eventType === 'move') {
           this.setState({ showMoves: !this.state.showMoves }, this.renderHeatmap);
       }
   }

   handleShowHeatmap = (website) => {
     this.getPage(website);
   }

   handleRemoveHeatmap = (website) => {
     this.removePage(website);
   }

   handleNewHeatmap = () => {
     this.setState({
       newHeatmap : true
     });
   }

    render() {
        const { user } = this.props;
        const { showHeatmap, newHeatmap, listHeatmaps, msgError } = this.state;
        if(!user.uid) return <Redirect to='/login' />
        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Heatmap
                                          <span className="float-right">
                                            {!showHeatmap && newHeatmap && listHeatmaps.length == 0 ? <></> :
                                              showHeatmap || newHeatmap ?
                                                <button className="btn btn-danger"
                                                  onClick={() => this.handleBackHeatmap()}>
                                                  Back
                                                </button>
                                                :
                                                <button className="btn btn-success"
                                                  onClick={() => this.handleNewHeatmap()}>
                                                  New
                                                </button>
                                            }
                                          </span>
                                        </h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="!#">Home</a></li>
                                            <li className="breadcrumb-item active">Heatmap</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                          <div className="container-fluid">
                          {this.state.showHeatmap ?
                            <div className="row">
                              <div className="col-md-12 text-center">
                                <button onClick={() => this.handleButtonClick('click')}>Cliques</button>
                                <button onClick={() => this.handleButtonClick('move')}>Movimentos</button>
                              </div>
                              <div className="col-md-12 text-center" id="heatmap">
                                <img src={this.state.imageSrc} />
                              </div>
                            </div>
                            :
                            <div className="row">
                              <div className="col-md-12 text-center">
                                {this.state.listHeatmaps.length == 0 || this.state.newHeatmap ?
                                  <>
                                    <FormWithConstraints onSubmit={this.handleSubmitNewHeatmap} noValidate>
                                      <div className="mb-12 center red-text">
                                          { msgError ? <p>{msgError}</p> : null }
                                      </div>
                                      <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name:</label>
                                        <Input
                                          type="text"
                                          id="name"
                                          name="name"
                                          className="form-control"
                                          required
                                          minLength={2}
                                        />
                                        <FieldFeedbacks for="name" />
                                      </div>
                                      <div className="mb-3">
                                        <label htmlFor="website" className="form-label">Website:</label>
                                        <Input
                                          type="url"
                                          id="website"
                                          name="website"
                                          className="form-control"
                                          required
                                        />
                                        <FieldFeedbacks for="website" />
                                      </div>
                                      <button type="submit" className="btn btn-primary">Submit</button>
                                    </FormWithConstraints>
                                  </>
                                 :
                                 <>
                                   <table className="table">
                                     <thead>
                                       <tr>
                                         <th>Name</th>
                                         <th>Website</th>
                                         <th>Actions</th>
                                       </tr>
                                     </thead>
                                     <tbody>
                                     {this.state.listHeatmaps.map((data, index) => (
                                       <tr key={index}>
                                        <td>{data.name}</td>
                                        <td>{data.website}</td>
                                        <td>
                                          <button className="btn btn-info"
                                            onClick={() => this.handleShowHeatmap(data.website)}>
                                            View
                                          </button>
                                          <button className="btn btn-danger"
                                            onClick={() => this.handleRemoveHeatmap(data.website)}>
                                            Remove
                                          </button>
                                        </td>
                                       </tr>
                                     ))}
                                   </tbody>
                                 </table>
                                </>
                               }
                              </div>
                            </div>
                          }
                          </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
      user: state.firebase.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      saveWebHeatmap : (uid, name, website) => dispatch(saveWebHeatmap(uid, name, website)),
      removeHeatmap : (uid, website) => dispatch(removeHeatmap(uid, website))
    };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Heatmap);
export { connected as Heatmap };
