import React, { Component, Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getFirestore } from 'redux-firestore';

const icons = ["frown", "laugh", "meh", "smile"];

class Cards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: []
        };
    }

    componentDidMount = () => {
        this.getFeedbacks();
    }

    getFeedbacks = () => {
        const firestore = getFirestore();

        firestore.collection('users').onSnapshot((docs) => {
            let arr = [];
            docs.forEach((doc) => {
                const { name } = doc.data();
                firestore.collection('users').doc(doc.id).collection('feedback').get().then((data) => {
                    data.forEach(d => {
                        const { category, feedback, opinion } = d.data();

                        if(icons.includes(opinion)){
                            arr.push({
                                opinion: opinion,
                                feedback: feedback
                            });
                        }
                        
                    });
                    // console.log(arr);
                    this.setState({
                        cards: arr
                    });
                });
                
            });
        });
    }
    

    render() {
        return (
            <Fragment>
                <New2  data={this.state.cards} />
            </Fragment>
        );
    }
}

function Article(props) {
    var num = props.data;
    

    return (
        <div className="GrupoFeedback mx-auto d-block">
            <div className="GrupoFeedbackBallon"><img src={"img/dist/feedback/"+num.opinion+"1.png"} /></div>
            <div className="feedbacktext">
                <span>{num.feedback}</span>
            </div>
            <div className="GrupoFeedbackAspas"><img  src={"img/dist/feedback/"+num.opinion+"2.png"} /></div>
        </div>

    );
}

function SampleNextArrow(props){
    const { onClick } = props;

    return <div  className="arrowfalcon arrowfalconnext" 
                onClick={onClick}><i class="fas fa-chevron-right"></i></div>
}

function SamplePrevArrow(props){
    const { onClick } = props;

    return <div  className="arrowfalcon arrowfalconprev" 
                onClick={onClick}><i class="fas fa-chevron-left"></i></div>
}

function New2(props) {
    var data = props.data;

    var newsTemplate;
    var settings = {
        dots: false,
        infinite: data.length > 4,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    if(data.length > 0){
        newsTemplate = data.map(function(item, index){
            return(
                    <Article data={item} />
            );
        });
    }

    return (
        <div className='news'>
            <Slider {...settings}>{newsTemplate}</Slider>
        </div>
    );
}

export { Cards };