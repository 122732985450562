import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideBar, Header }  from './';
import apk from '../_helpers/app-debug.apk';
import { Link } from 'react-router-dom';

class Home extends Component {

    // constructor(props){
    //     super(props);
    // }
    
    downloadAPK = () => {
        console.log("apk");
    }

    render() {
        return ( 
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Dashboard</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item active">Home</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </section>
 
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div className="text-center">
                                                        <Link to={apk} target="_blank" download>
                                                            <img style={{cursor:"pointer"}}  width="572" src="https://www.apkworld.it/wp-content/uploads/2020/10/apk-cosa.jpg" className="img-circle elevation-2" />
                                                        </Link>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
const connected = connect()(Home);
export { connected as Home };