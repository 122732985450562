export function csvTojson(csv, split){
    var txtLines = csv.split(/\r\n|\n/);
    var json = [];

    for (var  i = 0; i < txtLines.length; i++){
        var  data = txtLines[i].split(split);
        // var  jsonLine = [];
        for(var  j = 0; j < data.length; j++){
            // jsonLine.push({
            //     id: j,
            //     text: data[j],
            //     selected: true
            // });
            json.push(data[j]);
        }
        // json.push(jsonLine);
    }

    return json;
}