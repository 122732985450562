import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';

// Replace this with your own config details
var config = {
    apiKey: "AIzaSyDAuiNht02eiyazmk4GDyhND3vtI_vF64U",
    authDomain: "synchtest-d13c2.firebaseapp.com",
    databaseURL: "https://synchtest-d13c2.firebaseio.com",
    projectId: "synchtest-d13c2",
    storageBucket: "synchtest-d13c2.appspot.com",
    messagingSenderId: "773114922928",
    appId: "1:773114922928:web:e0f9b35045e8f470e47875",
    measurementId: "G-QDMN4EQ709"
};

firebase.initializeApp(config);
firebase.firestore();

export const database = firebase.database();

export default firebase
