import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { history } from './_helpers';
import { alertActions } from './_actions';

import { PrivateRoute } from './_components';
import { Register, Forgot, Home, Message, Profile, Payment, Emails, Support, Heatmap, Feedback } from './pages';
import { AdminUsers, AdminMessages, AdminPrices, AdminCredits, AdminRules, AdminSupport, AdminFeedbacks } from './admin';
import Login from './pages/Login'
import { connect } from 'react-redux';
import Crontab from 'reactjs-crontab';

import { getFirestore } from 'redux-firestore';

import { updateAllSMSRulesAdminFirebase } from './_actions';
import moment from "moment";

const CronjobTask = () => {
  const [open, setOpen] = React.useState(null);

  const sayHello = () => {
    console.log('sayHello');
    console.log('return updateAllSMSRulesAdminFirebase');
        const firestore = getFirestore();
        const day_now = moment().format("DD");
        const upd_now = moment().format("DD-MM-YYYY");

        firestore.collection('admin').doc('rules').collection('values')
        .get().then(res => {
            res.forEach(e => {
                let data = e.data();
                console.log(e.id+" - "+(day_now - data.diarenovavel)%data.diasrenovaveis);
                if(((day_now - data.diarenovavel)%data.diasrenovaveis) == 0){

                    console.log("upd renovaveis");
                    firestore.collection('admin').doc('rules')
                    .collection('values').doc(e.uid).set({
                        smsdisponiveis: data.smsrenovaveis,
                        date_upd: upd_now
                    });

                }
                console.log(e.data());
            });
        });
  }

  const tasks = React.useMemo(
    () => [
      {
        fn: sayHello,
        config: '24 * * * *',
        id: '1'
      }
    ],
    []
  );

  return (

    <Crontab
      tasks={tasks}
      timeXone='UTC'
      dashboard={{
        hidden: true
      }}
    />
  )

}


class App extends Component {
  constructor(props){
    super(props);

    // history.listen((location, action) => {
    //   // clear alert on location change
    //   this.props.clearAlerts();
    // });

  }


  render() {
    // const { alert } = this.props;
    const {admin} = this.props;
    return (



          <CookiesProvider>
              <CronjobTask  />
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path='/login' component={Login} />
                <Route path='/register' component={Register} />
                <Route path='/forgot' component={Forgot} />
                <Route path='/message' component={Message} />
                <Route path='/profile' component={Profile} />
                <Route path='/payment' component={Payment} />
                <Route path='/emails' component={Emails} />
                <Route path='/support' component={Support} />
                <Route path='/heatmap' component={Heatmap} />
                <Route path='/feedback' component={Feedback} />

                <Route path='/admin/users' component={AdminUsers} />
                <Route path='/admin/messages' component={AdminMessages} />
                <Route path='/admin/prices' component={AdminPrices} />
                <Route path='/admin/credits' component={AdminCredits} />
                <Route path='/admin/rules' component={AdminRules} />
                <Route path='/admin/support' component={AdminSupport} />
                <Route path='/admin/feedback' component={AdminFeedbacks} />

                <Redirect from='*' to='/' />
              </Switch>
            </Router>
          </CookiesProvider>
    );
  }
}

const mapState = (state) => {
  return {
    admin: state.firebase.profile.admin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAllSMSRulesAdminFirebase : () => dispatch(updateAllSMSRulesAdminFirebase())
  }
}

export default connect(mapState, mapDispatchToProps)(App);
