import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, SideBar } from '.';
import { saveFeedback } from '../_actions';
import { FieldFeedbacks, FieldFeedback, FormWithConstraints } from 'react-form-with-constraints';
import { sub } from 'date-fns';
import { Cards } from '../_components/Cards';
import Slider from "react-slick";

const faratings = [
    { icon:"angry", label: "Very Poor", color: "#CE1713" },
    { icon:"frown", label: "Poor", color: "#D14609" },
    { icon: "meh", label: "Average", color: "#FFC100" },
    { icon: "smile", label: "Good", color: "#A3B300" },
    { icon: "laugh", label: "Excellent", color: "#3D9C28" }
];

const findIndexRating = (icon) => {
    let index = -1;
    faratings.map((f,i) => {
        if(f.icon == icon){
            index = i;
        };
    });

    return index;
}

class Feedback extends Component {
    constructor(props){
        super(props);

        this.state = {
            activeRating: '',
            message: '',
            category: '',
            submit: false
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = e => {
        const { name, value } = e.target;

        this.setState({
            [name]:value
        });
    }

    handleChangeCategory = cate => {
        console.log(cate);
        this.setState({
            category: cate
        });
    }

    handleChangeRating = click => {
        console.log(click);
        this.setState({
            activeRating: click
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        const { activeRating, category, message } = this.state;


        if(this.props.user.uid.length > 0 && activeRating.length > 0 && category.length > 0 && message.length > 0){
            this.props.saveFeedback(this.props.user.uid, activeRating, category, message);
            this.setState({
                submit: true
            })
        }

        // this.form.validateFields();

        // if (!this.form.isValid()) {
        //     console.log('form is invalid: do not submit');
        //   } else {
        //     console.log('form is valid: submit');
        //     this.props.saveFeedback(this.props.user.uid, activeRating, category, message);
        //     this.setState({
        //         submit: true
        //     })
        //   }
    }

    handleClickRatingPlus = () => {
        const { activeRating } = this.state;
        const ind = findIndexRating(activeRating);

        let i = faratings.length - 1;
        if(ind > -1 && (ind + 1) <= (faratings.length - 1)){
            i = ind + 1;
        }

        this.setState({
            activeRating: faratings[i].icon
        });
    }

    handleClickRatingMinus = () => {
        const { activeRating } = this.state;
        const ind = findIndexRating(activeRating);

        let i = 0;
        if(ind > -1 && (ind - 1) >= 0){
            i = ind - 1;
        }

        this.setState({
            activeRating: faratings[i].icon
        });
    }



    SubmitFeedback = () => {
        this.setState({
            submit: false
        })
    }

    render() {
        const { user } = this.props;

        if(!user.uid) return <Redirect to='/login' />


        const options = ["Suggestion","Something is not quite right","Compliment"];
        const { message, submit } = this.state;

        return (
            <div className="sidebar-mini layout-fixed" style={{height: 'auto'}}>
                <div className="wrapper">
                    <Header />
                    <SideBar />

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="!#">Home</a></li>
                                        <li className="breadcrumb-item active">Feedback</li>
                                    </ol>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 appcards">
                                    { submit ?
                                                        <p className="centerpage" style={{fontWeight:"bold", fontSize: "32px"}}>Thank you ! Your feedback has been submitted</p>
                                                :
                                                <>
                                                <h3 className="text-center" style={{"fontWeight":"bold"}}>We would like your feedback to improve our website</h3>

                                            <div className="card-body d-flex justify-content-lg-center flex-row">


                                                <FormWithConstraints ref={form => this.form = form} onSubmit={this.handleSubmit} noValidate>
                                                    <div className="mt-2 p-4 text-center">
                                                        <p className="px-3" style={{"marginBottom":"20px", "fontWeight":"bold", "fontSize": "22px"}}>What is your opinion of this page?</p>

                                                        <div className="d-flex flex-row justify-content-center mt-2 " >
                                                        <span className="rating-minus" onClick={this.handleClickRatingMinus}><i class="fa-3x fas fa-minus"></i></span>
                                                            {faratings.map((f,i) => {
                                                                return(
                                                                    <>
                                                                <div className={"rating"} onClick={() => this.handleChangeRating(f.icon)}>
                                                                    <input type="radio" name="ratings" required />
                                                                    <div className={"fa-layers icon-stack fa-fw fa-5x rating-"+f.icon}>
                                                                        <i className={"fas fa-circle icon-stack-3x "+(this.state.activeRating == f.icon ? "active" : "")} style={{color: "transparent"}}></i>
                                                                        <i className={"icon-stack-3x far fa-"+f.icon+" "+(this.state.activeRating == f.icon ? "active" : "")} data-fa-transform="shrink-6"></i>
                                                                    </div>
                                                                    <label className="row" style={{justifyContent:"center"}}>{f.label}</label>
                                                                    <div className="linefalcon" style={{borderColor: f.color}} />
                                                                </div>
                                                                    </>)

                                                            })}
                                                            <span className="rating-plus" onClick={this.handleClickRatingPlus}><i class="fa-3x fas fa-plus"></i></span>
                                                        </div>

                                                        <FieldFeedbacks for="ratings">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>

                                                        <hr/>
                                                        <div className="form-group mt-4 ">
                                                            <p className="px-3"  style={{"marginBottom":"20px", "fontWeight":"bold", "fontSize": "22px"}}>Please select your feedback category below</p>
                                                            <div className="d-flex flex-row justify-content-center mt-2">
                                                                <div className="btn-group btn-group-toggle btn-block" data-toggle="buttons">
                                                                    {options.map((o, i) => {
                                                                        return (
                                                                            <label className="btn btn-light btn-outline-dark m-2 rounded color-falc" onClick={() => this.handleChangeCategory(o)} style={{"fontSize":"x-large", "cursor":"pointer"}}>
                                                                                <input
                                                                                    type="radio"
                                                                                    name="category"
                                                                                    value={o}
                                                                                    required
                                                                                />
                                                                                {o}
                                                                            </label>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <FieldFeedbacks for="category">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                        <hr/>
                                                        <div className="form-group mt-4 ">
                                                            <p className="px-3" style={{"marginBottom":"20px", "fontWeight":"bold", "fontSize": "22px"}}>Please leave your feedback bellow</p>

                                                            <div style={{position:"relative"}}>
                                                                <textarea
                                                                    style={{display:"block", backgroundColor:"#362544", color:"white", fontSize:"22px", fontWeight:"bold"}}
                                                                    className="form-control"
                                                                    name="message"
                                                                    rows="8"
                                                                    placeholder="Your Message..."
                                                                    required
                                                                    onChange={this.handleChange}>{message}</textarea>

                                                                <button type="submit" style={{position:"absolute", fontWeight:"bold", backgroundColor:"white", bottom:"10px", right: "10px", color: "#362544", width:"70px", borderRadius:"15px"}}>SEND</button>
                                                            </div>

                                                            <FieldFeedbacks for="message">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>

                                                    </div>

                                                </FormWithConstraints>

                                            </div>
                                            </>
                                        }

                                                <span className={ submit ? "fixedfeedbacks" : ""}><Cards  /></span>

                                        </div>
                                    </div>
                                </div>
                            {/* </div> */}
                        </section>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveFeedback : (uid, opinion, category, message) => dispatch(saveFeedback(uid, opinion, category, message))
    };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Feedback);
export { connected as Feedback};
